import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { palette, media } from '../utils/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons';
import { faChalkboardTeacher } from '@fortawesome/pro-light-svg-icons';
import { faCommentAltEdit } from '@fortawesome/pro-light-svg-icons';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { buildLinkTo } from '../utils/i18n';
import { graphql } from 'gatsby';

const messages = defineMessages({
  assesment: { id: 'features.step.assesment' },
  teaching: { id: 'features.step.teaching' },
  between: { id: 'features.step.between' },
  followUps: { id: 'features.step.followUps' },

  title: { id: 'features.title' },
});

const Wrapper = styled.div`
  border: 1px solid ${palette.primary};
  border-radius: 9px;
  padding: 20px;
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Step = styled.h4`
  margin: 0;
  font-size: 1.2em;
  font-weight: 500;
  color: ${palette.text.secondary};
`;
const Description = styled.p`
  margin: 0;
  font-size: 0.9em;
  margin-left: 42px;
  margin-top: -10px;
  color: ${palette.text.tertiary};
`;
const Title = styled.p`
  font-size: 0.8em;
  opacity: 0.87;
  font-weight: 600;
  color: ${palette.primary};
  text-transform: uppercase;
  margin: 0;
`;

const BigFeatureWrapper = styled.div`
  flex-basis: 50%;
  max-width: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  ${media.phone`
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  `};
`;

class Features extends React.PureComponent {
  renderStepText = step => {
    let msg;
    switch (step) {
      case 'assesment':
        msg = messages.assesment;
        break;
      case 'teaching':
        msg = messages.teaching;
        break;
      case 'between_appointments':
        msg = messages.between;
        break;
      case 'follow_ups':
        msg = messages.followUps;
        break;
      default:
        return null;
    }
    return <FormattedMessage {...msg} />;
  };

  renderStepIcon = step => {
    let icon;
    switch (step) {
      case 'assesment':
        icon = faClipboardList;
        break;
      case 'teaching':
        icon = faChalkboardTeacher;
        break;
      case 'between_appointments':
        icon = faCommentAltEdit;
        break;
      case 'follow_ups':
        icon = faCalendarCheck;
        break;
      default:
        icon = null;
    }

    return (
      <div
        style={{
          width: 42,
          height: 42,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: '1.8em', color: palette.text.secondary }}
        />
      </div>
    );
  };

  renderFeature = ({ id, step, description, to }) => {
    const { internalLink, external } = to;
    return (
      <BigFeatureWrapper key={id} style={{}}>
        <Link
          to={buildLinkTo(
            { external, internal: internalLink },
            this.props.locale
          )}
          style={{ textDecoration: 'none' }}
        >
          <FeatureWrapper>
            {this.renderStepIcon(step)}
            <Step>{this.renderStepText(step)}</Step>
          </FeatureWrapper>
          <Description>{description}</Description>
        </Link>
      </BigFeatureWrapper>
    );
  };

  render() {
    const features = get(this, 'props.features', []);
    if (!features || !features.length) return null;

    return (
      <Wrapper>
        <Title>
          <FormattedMessage {...messages.title} />
        </Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: -10,
            marginRight: -10,
            flexWrap: 'wrap',
          }}
        >
          {features.map(this.renderFeature)}
        </div>
      </Wrapper>
    );
  }
}
export default Features;

export const featureFragment = graphql`
  fragment FeatureFragment on ContentfulProductUsage {
    id
    step
    description
    to {
      ...LinkFragment
    }
  }
`;
