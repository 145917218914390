import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { media } from '../utils/style';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 200px;
  opacity: ${x => (x.colored ? 1 : 0.72)};

  ${media.bigPhone`
      margin: -10px 5px;
  `};
`;

function Customer({ id, webSite, logo, colored }) {
  const hasUrl = !!webSite;

  const renderImg = () => (
    <Img
      fluid={logo.fluid}
      alt={logo.title}
      imgStyle={colored ? {} : { filter: 'grayscale(1)' }}
    />
  );
  return (
    <Wrapper colored>
      {hasUrl ? (
        <a href={webSite} target="_blank" rel="noopener">
          {renderImg()}
        </a>
      ) : (
        renderImg()
      )}
    </Wrapper>
  );
}
export default Customer;

export const customerFragment = graphql`
  fragment CustomerFragment on ContentfulCustomer {
    id
    webSite
    logo {
      title
      fluid(maxWidth: 300) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
