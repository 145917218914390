// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-author-bio-js": () => import("./../../../src/templates/author-bio.js" /* webpackChunkName: "component---src-templates-author-bio-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-client-faq-js": () => import("./../../../src/templates/client-faq.js" /* webpackChunkName: "component---src-templates-client-faq-js" */),
  "component---src-templates-client-home-js": () => import("./../../../src/templates/client-home.js" /* webpackChunkName: "component---src-templates-client-home-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-how-js": () => import("./../../../src/templates/how.js" /* webpackChunkName: "component---src-templates-how-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-research-js": () => import("./../../../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-sign-up-js": () => import("./../../../src/templates/sign-up.js" /* webpackChunkName: "component---src-templates-sign-up-js" */),
  "component---src-templates-use-case-js": () => import("./../../../src/templates/use-case.js" /* webpackChunkName: "component---src-templates-use-case-js" */)
}

