import React, { Component } from 'react';
import Dropdown from '../Dropdown';
import styled from 'styled-components';
import { palette } from '../../utils/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 350px;
  overflow: auto;
`;
const StyledLi = styled.li`
  color: ${props => (props.selected ? palette.primary : palette.text.primary)};

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
`;

const SelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid ${palette.midGray};
  border-radius: 3px;
  padding: 10px;
  color: ${palette.midGray};
  transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${palette.primary};
    border-color: ${palette.primary};
  }
`;
const Option = styled.div`
  padding: 5px 10px;
`;

const CaretDown = styled.i``;

const SelectValue = styled.span`
  color: ${palette.text.primary};
`;

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };

    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  toggleDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  render() {
    const { onChange, value, options, className } = this.props;
    return (
      <div className={className}>
        <SelectButton onClick={this.toggleDropDown}>
          <SelectValue>{value.label}</SelectValue>
          <FontAwesomeIcon icon={faCaretDown} />
        </SelectButton>
        <Dropdown
          left="0"
          right="-22px"
          top="-40px"
          isOpen={this.state.dropdownOpen}
          onOutsideClick={this.toggleDropDown}
        >
          <StyledUl>
            {options.map(option => (
              <StyledLi
                key={option.id}
                selected={option.id === value.id}
                onClick={() => {
                  onChange(option);
                  this.toggleDropDown();
                }}
              >
                <Option>{option.label}</Option>
              </StyledLi>
            ))}
          </StyledUl>
        </Dropdown>
      </div>
    );
  }
}

export default CustomSelect;
