import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import get from 'lodash/get';
import { notify } from 'react-notify-toast';
import { graphql } from 'gatsby';
import Input from '../components/forms/Input';
import Select from '../components/forms/Select';
import Button from '../components/Button';
import boards from '../constants/boards';
import Link from '../components/MyLink';
import { palette } from '../utils/style';
import { buildTo } from '../utils/i18n';
import Meta from '../components/Meta';
import { withEmpty } from '../layouts/empty';
import { windowSafe } from '../utils/misc';

const sortedBoards = sortBy(boards, ['label']);

const messages = defineMessages({
  namePlaceholder: { id: 'signup.name' },
  emailPlaceholder: { id: 'signup.email' },
  passwordPlaceHolder: { id: 'signup.password' },
  passwordExplanation: { id: 'signup.passwordExplanation' },
  signupBtn: { id: 'signup.submitBtn' },
  smallTerms: { id: 'signup.smallTerms' },
  terms: { id: 'signup.terms' },
  invalidError: { id: 'signup.invalidError' },
  usedError: { id: 'signup.usedError' },
  boardName: { id: 'signup.boardName' },
  permitNumber: { id: 'signup.permitNumber' },
  permitNumberExplanation: { id: 'signup.permitNumberExplanation' },
  aboutYou: { id: 'signup.aboutYou' },
  credentials: { id: 'signup.credentials' },
  smallTermsMiddle: { id: 'signup.smallTermsMiddle' },
  subscriptionTerms: { id: 'signup.subscriptionTerms' },
  logIn: { id: 'signup.logIn' },
  existingAccount: { id: 'signup.existingAccount' },
  creating: { id: 'signup.creating' },
});

const Title = styled.h1`
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  line-height: 1.2em;
  color: ${palette.text.primary};
`;

const Subtitle = styled.h3`
  margin: 0 auto 40px;
  text-align: center;
  font-size: 1.3em;
  color: ${palette.text.secondary};
`;

const BlueLink = styled(Link)`
  color: ${palette.primary};
  transition: color 0.1 ease-in-out;

  &:hover {
    color: ${palette.primarySaturated};
  }
`;

class Signup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      board: boards[5],
      boardName: '',
      permitNumber: '',

      creating: false,
    };

    this._submit = throttle(this._submit, 400, this);
  }

  _onChangeName = e => {
    const name = e.target.value;
    this.setState({ name, showErrors: false });
  };
  _onBoardNameChange = e => {
    const boardName = e.target.value;
    this.setState({ boardName });
  };
  _onPermitNumberChange = e => {
    const permitNumber = e.target.value;
    this.setState({ permitNumber });
  };
  _onChangeEmail = e => {
    const email = e.target.value;
    this.setState({ email, showErrors: false });
  };
  _onChangePassword = e => {
    const password = e.target.value;
    this.setState({ password, showErrors: false });
  };
  _onKeyPress = e => {
    if (e.key === 'Enter' && this._canSubmit()) {
      this._submit();
    }
  };

  _getErrorMsg = type => {
    const { intl } = this.props;
    switch (type) {
      case 'email_or_pass':
        return intl.formatMessage(messages.usedError);
      case 'invalid_email':
        return intl.formatMessage(messages.invalidError);
      default:
        return intl.formatMessage(messages.invalidError);
    }
  };

  _submit = e => {
    e.preventDefault();
    const {
      name,
      email,
      password,
      board,
      boardName,
      permitNumber,
    } = this.state;
    this.setState({
      creating: true,
    });

    const { locale } = this.props.pageContext;
    const body = {
      name,
      email,
      password,
      board: {
        id: board.id,
        name: boardName,
      },
      permitNumber,
      locale,
    };
    fetch('/v1/dietitians/register', {
      method: 'post',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(body),
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          return res.json();
        } else {
          return res.json().then(x => Promise.reject(x));
        }
      })
      .then(res => {
        try {
          // Track conversion event on Google Tag Manager
          windowSafe().dataLayer.push({
            event: 'start-free-trial',
            eventAction: 'Click',
            eventCategory: 'CompleteRegistration',
            eventLabel: 'start-free-trial',
          });
        } catch (e) {
          // Should not throw, but we do not want to risk blocking the
          // redirection for a tracking issue.
          console.log('Error sending  conversion event');
        }

        // Redirect to the app
        windowSafe().location = `https://app.keenoa.co/welcome?token=${
          res.token
        }`;
      })
      .catch(error => {
        notify.show(this._getErrorMsg(error.type), 'error');
      })
      .finally(() => {
        this.setState({
          creating: false,
        });
      });
  };

  _selectBoard = newBoard => {
    this.setState({
      board: newBoard,
    });
  };

  _canSubmit = () => {
    const {
      name,
      email,
      password,
      board,
      boardName,
      permitNumber,
    } = this.state;
    return (
      name &&
      email &&
      (password && password.length > 5) &&
      board &&
      permitNumber &&
      (board.id !== 0 || boardName)
    );
  };

  renderTerms() {
    const locale = get(this, 'props.pageContext.locale');
    return (
      <div
        style={{
          color: palette.text.tertiary,
          fontSize: '0.8em',
          fontWeight: 600,
        }}
      >
        <FormattedMessage {...messages.smallTerms} />
        <BlueLink to={buildTo('/policy/terms', locale)}>
          <FormattedMessage {...messages.terms} />
        </BlueLink>
        <FormattedMessage {...messages.smallTermsMiddle} />
        <BlueLink to={buildTo('/policy/subscription', locale)}>
          <FormattedMessage {...messages.subscriptionTerms} />
        </BlueLink>
        .
      </div>
    );
  }

  render() {
    const { formatMessage } = this.props.intl;
    const {
      name,
      email,
      password,
      board,
      boardName,
      permitNumber,
    } = this.state;

    const data = get(this, 'props.data.contentfulPage');

    const meta = get(data, 'meta');
    const header = get(data, 'header');

    return (
      <form
        onKeyPress={this._onKeyPress}
        autoComplete="on"
        onSubmit={this._submit}
        style={{
          maxWidth: 510,
          margin: '0 auto',
        }}
      >
        <Meta {...meta} />
        <Title>{header.title}</Title>
        <Subtitle>{header.subTitle}</Subtitle>
        <Input
          autoFocus
          type="text"
          name="fname"
          autoComplete="name"
          placeholder={formatMessage(messages.namePlaceholder)}
          value={name}
          onChange={this._onChangeName}
        />
        <Select
          value={board}
          options={sortedBoards}
          onChange={this._selectBoard}
        />
        {board.id !== 0 ? null : (
          <Input
            placeholder={formatMessage(messages.boardName)}
            value={boardName}
            onChange={this._onBoardNameChange}
          />
        )}
        <Input
          placeholder={formatMessage(messages.permitNumber)}
          value={permitNumber}
          onChange={this._onPermitNumberChange}
          style={{ marginBottom: 0 }}
          explanation={formatMessage(messages.permitNumberExplanation)}
        />
        <Input
          name="email"
          autoComplete="email"
          placeholder={formatMessage(messages.emailPlaceholder)}
          value={email}
          onChange={this._onChangeEmail}
        />
        <Input
          placeholder={formatMessage(messages.passwordPlaceHolder)}
          name="password"
          autoComplete="new-password"
          type="password"
          value={password}
          onChange={this._onChangePassword}
          explanation={formatMessage(messages.passwordExplanation)}
        />
        <Button
          btnType="primary"
          disabled={!this._canSubmit()}
          style={{
            width: '100%',
            marginTop: '20px',
          }}
        >
          <FormattedMessage
            {...(this.state.creating ? messages.creating : messages.signupBtn)}
          />
        </Button>
        <p
          style={{
            marginTop: '5px',
            color: palette.text.tertiary,
            fontSize: '0.8em',
            fontWeight: 600,
          }}
        >
          <FormattedMessage {...messages.existingAccount} />{' '}
          <BlueLink to="https://app.keenoa.co/login">
            <FormattedMessage {...messages.logIn} />
          </BlueLink>
        </p>
        {this.renderTerms()}
      </form>
    );
  }
}

export default withEmpty(injectIntl(Signup));
export const pageQuery = graphql`
  query SignupPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      header {
        ...HeaderFragment
      }
      meta {
        ...MetaFragment
      }
    }
  }
`;
