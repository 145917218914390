import get from 'lodash/get';
import mailtoLink from 'mailto-link';

export const buildTo = (to, locale) => {
  const t = to.split('/fr').join('');
  return locale && locale === 'fr' ? '/fr' + t : t;
};

export const buildLinkTo = ({ external, internal, email }, locale) => {
  if (external) return external;

  if (email) {
    const { to, subject = '', body = { body: '' } } = email;
    return mailtoLink({ to, subject, body: body.body });
  }

  const internalPath = get(internal, 'path');
  return internalPath ? buildTo(internalPath, locale) : null;
};
