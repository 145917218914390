import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';
import { palette, media } from '../utils/style';
import Button from './Button';
import Link from './MyLink';
import { buildLinkTo } from '../utils/i18n';
import { graphql } from 'gatsby';

const Details = styled.p`
  text-align: center;
  color: ${palette.text.secondary};
  font-size: 0.9em;
  margin-top: 5px;
  font-weight: 600;
  max-width: 290px;
`;

const CtaButton = styled(Button)`
  z-index: 1;
  margin-top: 20px;
  width: 100%;
  margin-left: ${x => (x.outlined ? 8 : 0)}px;
  white-space: nowrap;

  ${media.desktop`
    padding: 18px 9px;
  `};

  ${media.tablet`
    width: 100%;
    min-width: 0;
    margin-left: 0;
  `};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  ${media.tablet`
    max-width: 10000px;
  `};
`;

class CallToAction extends React.PureComponent {
  getButtonColor = () => {
    const { accent, outlined } = this.props;
    const color = accent ? palette.accent : palette.primary;
    return outlined
      ? {
          border: `2px solid ${color}`,
          color,
          backgroundColor: 'white',
        }
      : {
          backgroundColor: color,
        };
  };

  render() {
    const title = get(this, 'props.title');
    const detail = get(this, 'props.detail');
    const locale = get(this, 'props.node_locale');

    const { internalLink, emailLink, external } = get(this, 'props.to');
    return (
      <ButtonWrapper style={{ ...this.props.style }}>
        <Link
          to={buildLinkTo(
            { external, internal: internalLink, email: emailLink },
            locale
          )}
        >
          <div style={{ width: '100%' }}>
            <CtaButton
              outlined={this.props.outlined}
              style={{
                zIndex: 1,
                marginTop: 20,
                ...this.getButtonColor(),
              }}
            >
              {title}
            </CtaButton>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Details style={this.props.light ? { fontWeight: 400 } : {}}>
                {detail}
              </Details>
            </div>
          </div>
        </Link>
      </ButtonWrapper>
    );
  }
}
export default CallToAction;
CallToAction.propTypes = {
  // Light mode makes the detail text lighter
  light: PropTypes.bool, // default false

  // Accent mode makes the button use the accent color
  accent: PropTypes.bool, // default false
};
export const ctaFragment = graphql`
  fragment CallToActionFragment on ContentfulCallToAction {
    title
    node_locale
    detail
    to {
      ...LinkFragment
    }
  }
`;
