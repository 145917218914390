import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`

class Cards extends React.PureComponent {
  render() {
    const { cards, onSelect, selectable } = this.props
    return <Wrapper>{this.props.children}</Wrapper>
  }
}

export default Cards
