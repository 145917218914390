import React from 'react';
import { addLocaleData } from 'react-intl';
import localeData from 'react-intl/locale-data/fr';
import { graphql, StaticQuery } from 'gatsby';
import Layout from './blog';
import messages from '../../i18n/blog/fr.js';

addLocaleData(localeData);

const query = graphql`
  query IndexLayoutQueryFr {
    contentfulAsset(
      contentful_id: { eq: "5upfvCFfeoiwkMkMSqgoEk" }
      node_locale: { eq: "fr" }
    ) {
      fixed(width: 150) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    siteMap: contentfulLinksBar(
      contentful_id: { eq: "5DFP8rJbZSqukYAgiu2geo" }
      node_locale: { eq: "fr" }
    ) {
      links {
        ...LinkFragment
        ...LinksGroupFragment
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={query}
    render={data => (
      <Layout data={data} {...props} locale="fr" messages={messages} />
    )}
  />
);
