import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { palette, media } from '../utils/style';
import Meta from '../components/Meta';
import Supporters from '../components/Supporters';
import Img from 'gatsby-image';
import { defineMessages, FormattedMessage } from 'react-intl';
import PageFooter from '../components/PageFooter';
import vedette from './vedette.png';
import { graphql } from 'gatsby';
import { withHome } from '../layouts/home';

const messages = defineMessages({
  mission: { id: 'about.mission' },
  team: { id: 'about.team' },
  supporters: { id: 'about.supporters' },
});

const MembersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${media.bigPhone`
    flex-wrap: wrap;
  `};
`;

const MembersBackground = styled.div`
  padding: 50px 35px;
  border-radius: 16px;
  z-index: 1;
  background-color: #f7f7f7;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 50%;

  ${media.tablet`
    width: 100%;
  `};
  ${media.bigPhone`
  margin-left: 0;
`};
`;

const MissionStatement = styled.h1`
  font-weight: 600;
  max-width: 600px;
  font-size: 2.3em;
  line-height: 1.5em;
  flex: 2;
  color: ${palette.text.primary};
  margin-top: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  ${media.tablet`
    font-size: 2.3em;
    position: static;
  `};
  ${media.bigPhone`
    font-size: 2em;
  `};
`;
const Mission = styled.h2`
  flex: 1;
  font-size: 1.5em;
  padding: 0 20px;
  margin-top: 50px;
  font-weight: 300;
  font-family: IBM Plex Serif, serif;

  ${media.desktop`
    font-size: 1.4em;
  `};
  ${media.tablet`
    font-size: 1.2em;
  `};
  & > p {
    margin-top: 0;
  }
`;

const MissionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3em;
  align-items: center;
  position: relative;

  ${media.tablet`
    flex-wrap: wrap;
    margin-bottom: 40px;
  `};
`;

const DietitianImage = styled.img`
  position: absolute;
  bottom: -325px;
  right: -75px;
  width: 375px;
  height: auto;

  ${media.tablet`
  right: 0;
  width: 275px;
  bottom: -205px;
  `};
`;

const Image = styled(Img)`
  width: 50%;
  margin-right: 20px;

  ${media.bigPhone`
    display: none;
  `};
`;

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;

  flex-basis: 33%;
  width: 33%;
  flex: 0 0 auto;

  flex-grow: 1;

  ${media.desktop`
    flex-basis: 50%;
    max-width: 50%;
    width: 50%;
  `};
  ${media.phone`
   flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  `};
`;

const Why = styled.div`
  height: 30px;
  text-transform: uppercase;
  color: ${palette.accent};
  font-size: 16px;
  padding-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
`;

class About extends React.PureComponent {
  renderMember(member) {
    return (
      <MemberWrapper key={member.name}>
        <div style={{ padding: 10 }}>
          <Image
            fixed={member.image.fixed}
            alt={member.image.description}
            style={{ borderRadius: '50%' }}
          />
          <p style={{ margin: '10px 0 5px 0', color: palette.text.primary }}>
            {member.name}
          </p>
          <p style={{ margin: 0, color: palette.text.secondary }}>
            {member.headline}
          </p>
        </div>
      </MemberWrapper>
    );
  }

  render() {
    const data = get(this, 'props.data.contentfulAboutPage');
    const meta = get(data, 'meta');
    const pageFooter = get(data, 'pageFooter');
    const teamMembers = get(data, 'teamMembers');
    const supporters = get(data, 'supporters');
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Meta {...meta} />
        <MissionWrapper>
          <ContentWrapper>
            <MissionStatement>
              <Why>
                <FormattedMessage {...messages.mission} />
              </Why>
              {get(data, 'missionStatement')}
              <DietitianImage src={vedette} />
            </MissionStatement>
          </ContentWrapper>
          <ContentWrapper>
            <Mission
              dangerouslySetInnerHTML={{
                __html: get(data, 'mission.childMarkdownRemark.html'),
              }}
            />
          </ContentWrapper>
        </MissionWrapper>
        <div style={{ height: 120 }} />
        {/* <MembersBackground>
          <Why style={{ textAlign: 'center', paddingBottom: 20 }}>
            <FormattedMessage {...messages.team} />
          </Why>
          <MembersWrapper>{teamMembers.map(this.renderMember)}</MembersWrapper>
        </MembersBackground> */}
        <Why style={{ paddingTop: 45 }}>
          <FormattedMessage {...messages.supporters} />
        </Why>
        <Supporters supporters={supporters} />
        <PageFooter {...pageFooter} />
      </div>
    );
  }
}
export default withHome(About);

export const pageQuery = graphql`
  query AboutPageQuery($id: String!) {
    contentfulAboutPage(id: { eq: $id }) {
      meta {
        ...MetaFragment
      }
      missionStatement
      mission {
        childMarkdownRemark {
          html
        }
      }
      missionImage {
        fluid(maxWidth: 750) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      pageFooter {
        ...PageFooterFragment
      }
      teamMembers {
        name
        headline
        image {
          fixed(height: 225, width: 225, resizingBehavior: THUMB) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          description
        }
      }
      supporters {
        fixed(width: 325) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
    }
  }
`;
