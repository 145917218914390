/*eslint-disable max-len,quotes*/
export default {
  'covid.banner': 'COVID-19 Response: Extending free trial, Removing Limits',
  'covid.learnMore': 'Learn more',

  'tabBar.getStarted': 'Try Keenoa for 14 days',

  'policy.terms.link': 'Terms',
  'policy.privacy.link': 'Privacy',
  'policy.subscription.link': 'Subscription',

  'signup.name': 'Full Name',
  'signup.email': 'Email',
  'signup.password': 'Password',
  'signup.passwordExplanation':
    'Make sure that your password has at least 6 characters.',
  'signup.submitBtn': 'Sign up',
  'signup.smallTerms': 'By signing in, you agree to the ',
  'signup.invalidError': 'The provided email is not valid.',
  'signup.usedError': 'The provided email is already in use.',
  'signup.terms': 'Terms of Service',
  'signup.boardName': 'Board of professional name',
  'signup.permitNumber': 'Licence number',
  'signup.permitNumberExplanation':
    'We need this information to validate your professional status.',
  'signup.aboutYou': 'Dietitian',
  'signup.credentials': 'Credentials',
  'signup.smallTermsMiddle': ' and the ',
  'signup.subscriptionTerms': 'Registration and Subscription Conditions',
  'signup.logIn': 'Log in',
  'signup.existingAccount': ' Already have an account ?',
  'signup.creating': 'Processing...',

  'questions.goal.health': 'Health and fitness',
  'questions.goal.weightLoss': 'Weight management',
  'questions.goal.diabete': 'Diabetes',
  'questions.goal.hypertension': 'Cardiovascular health',
  'questions.goal.allergy': 'Allergies',
  'questions.goal.pregnancy': 'Pregnancy',
  'questions.goal.vegan': 'Vegetarianism / veganism',
  'questions.goal.performance': 'Performance',
  'questions.goal.other': 'Other',
  'questions.goal.header': 'My <b>nutritional goal</b> is :',
  'questions.goal.subheader':
    'We make sure to match you with a dietitian who specializes for your needs.',

  'questions.motivation.not': 'Unmotivated',
  'questions.motivation.little': 'Somewhat motivated',
  'questions.motivation.motivated': 'Motivated',
  'questions.motivation.very': 'Very motivated',
  'questions.motivation.extremely': 'Extremely motivated',
  'questions.motivation.header':
    'I feel<b> ___________ </b>to work towards reaching my nutritional objectives.',
  'questions.motivation.subheader':
    'Your dietitian will taillor his approach based on your level of motivation.',

  'questions.history.weightLoss': 'Weight management program',
  'questions.history.dietitian': 'Registered dietitian',
  'questions.history.alternative': 'Trainer or naturopath',
  'questions.history.apps': 'Fitness/nutrition app',
  'questions.history.other': 'Other',
  'questions.history.none': 'None',
  'questions.history.header':
    'I have previously consulted/received <b>nutritional advice</b> from:',
  'questions.history.subheader':
    'Having an idea of your history will accelerate your evaluation.',
  'questions.history.nextStep': 'Next',

  'questions.address.header':
    'I want my consultations to be <b>located near</b>:',
  'questions.address.subheader':
    'We make sure that it is convenient for you to meet your dietitian.',
  'questions.address.nextStep': 'Next step',
  'questions.address.lastStep': 'Last step',
  'questions.address.placeholder': 'City, postal code or place...',

  'questions.email.header': 'My <b>email address</b> is:',
  'questions.email.placeholder': 'Email*',
  'questions.email.find': 'Next step',
  'questions.email.terms':
    '*By providing your email address, you agree upon being contacted by a dietitian to discuss your needs.',

  'questions.request.header': 'We will find you a <b>perfect match</b>',
  'questions.request.subheader':
    'Our team will personally introduce you to a registered dietitian that precisely fits your needs. Let us know if you have any other needs so we can make sure you get the best care!',
  'questions.request.find': 'Submit Request',
  'questions.request.placeholder':
    'Eg. It is for a couple consultation, I prefer if...',

  'questions.confirmation.header': 'Awesome, an email has been sent your way.',
  'questions.confirmation.subheader':
    'Thank you for submitting your request, our team will personally introduce you to the best dietitian for you.',
  'questions.confirmation.backHome': 'Back home',

  'pricing.faq': 'Frequently Asked Questions',

  'about.mission': 'Our Mission',
  'about.team': 'Our Team',
  'about.supporters': 'Supporters',

  'policy.lastUpdate': 'Last Update: ',

  'footer.terms': 'Terms of use',
  'footer.privacy': 'Privacy policy',

  'home.learn': 'Learn more',
  'home.supporters': 'Our supporters',
  languages: 'Languages',
};
