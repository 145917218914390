import React from 'react';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { palette, media } from '../utils/style';
import PostListing from '../components/post-listing';
import { graphql } from 'gatsby';
import { withBlog } from '../layouts/blog';

const ImageWrapper = styled.div`
  width: 175px;
  height: 175px;

  & > div {
    border-radius: 50%;
    overflow: hidden;
    width: 175px;
    height: 175px;
  }
`;

const Halo = styled.figure`
  box-shadow: ${palette.primary} 0px 0px 0px 4px;
  width: 201px;
  min-width: 201px;
  height: 201px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 30px;
  border-radius: 50%;

  ${media.tablet`
    margin-right: 0;
  `};
`;

const Name = styled.h1`
  ${media.tablet`
    margin: 0;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row wrap;
  padding: 50px;

  ${media.tablet`
    padding: 30px;
    flex-direction: column;
    align-items: center;
  `};
`;
const TextWrapper = styled.div`
  margin-bottom: -15px;
  display: flex;
  max-width: 750px;
  flex-direction: column;
  ${media.tablet`
    align-items: center;
  `};
`;
const Bio = styled.p`
  border-bottom: 3px solid ${palette.primary};
  padding-bottom: 15px;

  & > p {
    margin: 0;
  }
`;

class RootIndex extends React.Component {
  renderHeader() {
    const author = get(this, 'props.data.contentfulPerson');

    const metaUrl = `https://keenoa.com/blog/author/${author.slug}`;
    const metaTitle = `${author.name}, Author at Keenoa`;
    const metaImage = author.image.file.url;
    const metaDescription = get(author, 'shortBio.shortBio');

    return (
      <Helmet title={metaTitle}>
        <meta name="description" content={metaDescription} />
        {/* OpenGraph tags */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:description" content={metaDescription} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {author.twitter ? (
          <meta name="twitter:creator" content={author.twitter} />
        ) : null}
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
      </Helmet>
    );
  }

  render() {
    const posts = get(this, 'props.data.contentfulPerson.blog_post___page');
    const author = get(this, 'props.data.contentfulPerson');

    return (
      <div>
        {this.renderHeader()}
        <Wrapper>
          <Halo>
            <ImageWrapper>
              <Img fixed={author.image.fixed} alt="" />
            </ImageWrapper>
          </Halo>
          <TextWrapper>
            <Name style={{ color: palette.text.primary }}>{author.name}</Name>
            <Bio
              style={{ color: palette.text.secondary }}
              dangerouslySetInnerHTML={{
                __html: author.shortBio.childMarkdownRemark.html,
              }}
            />
          </TextWrapper>
        </Wrapper>
        <div style={{ height: 30 }} />
        <PostListing posts={posts} />
      </div>
    );
  }
}

export default withBlog(RootIndex);

export const pageQuery = graphql`
  query AuthorQuery($id: String!) {
    contentfulPerson(id: { eq: $id }) {
      name
      shortBio {
        childMarkdownRemark {
          html
        }
        shortBio
      }
      title
      image {
        fixed(height: 175, width: 175, resizingBehavior: THUMB) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
        file {
          url
        }
      }
      blog_post___page {
        ...ArticlePreviewFragment
      }
    }
  }
`;
