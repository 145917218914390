import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'react-tippy';
import { palette, media } from '../utils/style';
import BarLink, { LinkStyle, LinkDiv } from '../components/BarLink';
import styled from 'styled-components';
import MyLink from '../components/MyLink';
import { buildTo } from '../utils/i18n';
import { defineMessages, FormattedMessage } from 'react-intl';
import { windowSafe } from '../utils/misc';

const messages = defineMessages({
  languages: { id: 'languages' },
});

export const LinkI18n = styled(MyLink)`
  ${LinkStyle};
  margin-left: 1.8em;
  margin-right: 0;
`;

const LinkInList = styled.div`
  padding: 10px 0;
  color: ${palette.text.secondary};
  font-weight: 600;
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${palette.text.primary};
    cursor: pointer;
  }
`;

const availableLocales = [
  {
    id: 'fr',
    small: 'FR',
    full: 'Francais - FR',
  },
  {
    id: 'en',
    small: 'EN',
    full: 'English - EN',
  },
];

const Wrapper = styled.div`
  margin-left: 1.8em;

  ${media.desktop`
    margin-left: 0;
  `};
`;

export class MobilePicker extends React.PureComponent {
  render() {
    const { location } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderTop: `1px solid ${palette.lightGray}`,
          marginTop: 15,
        }}
      >
        <LinkDiv style={{ paddingBottom: 0 }}>
          <FormattedMessage {...messages.languages} />
        </LinkDiv>
        {availableLocales.map(({ id, full }, i) => (
          <MyLink
            key={id}
            style={{
              textDecoration: 'none',
              padding: '4px 0',
              color: palette.text.primary,
            }}
            to={buildTo(windowSafe().location.pathname, id)}
          >
            {full}
          </MyLink>
        ))}
      </div>
    );
  }
}

class LocalePicker extends React.PureComponent {
  state = {
    open: false,
  };

  _makeToggle = to => () =>
    this.setState({
      open: to,
    });

  renderLocale = ({ id, full, style }) => {
    const to = buildTo(windowSafe().location.pathname, id);
    return (
      <MyLink key={id} to={to}>
        <LinkInList style={style}>{full}</LinkInList>
      </MyLink>
    );
  };

  render() {
    const { locale } = this.props;
    const current = availableLocales.find(({ id }) => id === locale);
    return (
      <Wrapper>
        <Tooltip
          position="left-start"
          trigger="click"
          interactive
          interactiveBorder={20}
          arrow={false}
          useContext
          duration={110}
          theme="light"
          onShow={this._makeToggle(true)}
          onHide={this._makeToggle(false)}
          offset={0}
          html={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {availableLocales.map(this.renderLocale)}
            </div>
          }
        >
          <BarLink
            title={current.small}
            renderTitle={() => (
              <div>
                <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 2 }} />
                {current.small}
              </div>
            )}
            style={{
              color: this.state.open ? palette.primarySaturated : null,
              marginLeft: 0,
              marginRight: 0,
            }}
          />
        </Tooltip>
      </Wrapper>
    );
  }
}

export default LocalePicker;
