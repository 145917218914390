import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { palette, media } from '../utils/style';
import CallToAction from './CallToAction';
import { graphql } from 'gatsby';

const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const Wrapper = styled.div`
  max-width: 750px;
  text-align: center;
  padding-top: 2em;
  margin: 0 auto;
`;
const WithImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-right: ${x => (x.focus ? '-150px' : 0)};
  ${media.tablet`
    margin: auto;
    flex-wrap: wrap;
  `};
  ${media.phone`
    flex-wrap: wrap;
   
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  ${media.tablet`
    width: 100%;
    flex: 1;
    margin: auto;
    flex-direction: column-reverse;
  `};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  justify-content: center;

  width: ${props => (props.image ? '60%' : '40%')};
  order: ${props => (props.image ? 1 : 2)};

  margin-left: ${props => (props.image ? '-50px' : 0)}};

  ${media.desktop`
    width: ${props => (props.image ? '65%' : '35%')};
  `};

  ${media.tablet`
    margin-left: 0;
    width: 100%;
    order: ${props => (props.image ? 2 : 1)};
  `};
  ${media.bigPhone`
    margin-left: 0;
    ${x => (x.image ? 'margin: -20px -20%; width: 120%' : '')}
  `};
`;
const Title = styled.h1`
  font-size: 2.6em;
  line-height: 1.2em;
  font-weight: 600;
  color: ${palette.text.primary};
  margin-bottom: 10px;
  margin-top: 0;

  text-align: ${x => (x.left ? 'left' : 'center')};

  ${media.desktop`
    font-size: 2.4em;
  `};
  ${media.tablet`
  text-align: center;
    font-size: 2.2em;
  `};
  ${media.bigPhone`
    font-size: 2em;
  `};
`;
const SubTitle = styled.h2`
  text-align: ${x => (x.left ? 'left' : 'center')};
  font-size: ${x => (x.large ? 1.3 : 1)}em;
  margin-top: 20px;
  margin-bottom: 15px;
  color: ${palette.text.secondary};
  ${media.tablet`
    margin-left: 0;
    width: 100%;
    text-align: center;
  `};
  ${media.bigPhone`
    font-size: ${x => (x.large ? 1.2 : 1)}em;
  `};
`;

const Image = styled(Img)`
  height: 100%;

  & > div {
    margin-left: auto;
  }

  ${media.tablet`
    max-height: 350px !important;
    /* width: auto !important; */
  `}
  & img {
    ${media.tablet` 
      max-height: 350px !important;
      /* width: auto !important; */
  `};
  }
`;

const TwoImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: -20vw;
  transition: all 0.15s ease-in-out;

  ${media.desktop` 
    padding: 0 25px;
    margin-top: -25vw;
    justify-content: space-between;
  `};

  ${media.tablet` 
    z-index: -1;
    padding: 0;
    margin-left: -10vw;
    margin-right: -10vw;
    margin-top: -85vw;
    flex-direction: column-reverse;
  `};
`;

const DualImageWrapper = styled.div`
  width: 25%;

  ${media.desktop` 
      width: 30%;
  `};

  ${media.tablet` 
      width: 65%;
      opacity: 0.4;
      ${x => (x.last ? 'margin-left: auto;margin-right: -27vw;' : '')};
      ${x => (x.last ? '' : 'margin-top: -20vw;')};
  `};
`;

// This component renders the "standard header". It uses props bases
// on the "Header" Content Model in Contentful
class Header extends React.PureComponent {
  renderWithImages = (
    title,
    subTitle,
    imageFluid,
    secondImageFluid,
    callToAction
  ) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {this.renderWithoutImages(title, subTitle, callToAction)}
        <TwoImagesWrapper>
          <DualImageWrapper image>
            <Img fluid={imageFluid} />
          </DualImageWrapper>
          <DualImageWrapper last>
            <Img fluid={secondImageFluid} />
          </DualImageWrapper>
        </TwoImagesWrapper>
      </div>
    );
  };

  renderWithImage = (
    title,
    subTitle,
    imageFluid,
    callToAction,
    secondCallToAction
  ) => {
    return (
      <WithImageWrapper focus={this.props.imageFocus}>
        <TextWrapper image>
          {!imageFluid ? null : (
            <Image
              fluid={imageFluid}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
            />
          )}
        </TextWrapper>
        <TextWrapper>
          <Title left>{title}</Title>
          <SubTitle left>{subTitle}</SubTitle>
          <ButtonWrapper>
            {!callToAction ? null : (
              <CallToAction {...callToAction} light style={{ flex: 2 }} />
            )}
            {!secondCallToAction ? null : (
              <CallToAction
                {...secondCallToAction}
                light
                outlined
                style={{ flex: 1 }}
              />
            )}
          </ButtonWrapper>
        </TextWrapper>
      </WithImageWrapper>
    );
  };

  renderWithoutImages = (title, subTitle, callToAction) => {
    return (
      <div style={{ position: 'relative', marginBottom: '5em' }}>
        <Wrapper>
          <Title>{title}</Title>
          <SubTitle large>{subTitle}</SubTitle>
          {!callToAction ? null : (
            <div
              style={{
                padding: '20px 0',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <CallToAction {...callToAction} />
            </div>
          )}
        </Wrapper>
        <TopWrapper />
      </div>
    );
  };

  render() {
    const title = get(this, 'props.title');
    const subTitle = get(this, 'props.subTitle');
    const imageFluid = get(this, 'props.image.fluid', undefined);
    const secondImageFluid = get(this, 'props.secondImage.fluid', undefined);
    let callToAction = get(this, 'props.callToAction');
    let secondCallToAction = get(this, 'props.secondCallToAction');
    if (!callToAction && secondCallToAction) {
      callToAction = secondCallToAction;
      secondCallToAction = undefined;
    }

    if (secondImageFluid)
      return this.renderWithImages(
        title,
        subTitle,
        imageFluid,
        secondImageFluid,
        callToAction,
        secondCallToAction
      );
    if (imageFluid)
      return this.renderWithImage(
        title,
        subTitle,
        imageFluid,
        callToAction,
        secondCallToAction
      );

    return this.renderWithoutImages(
      title,
      subTitle,
      callToAction,
      secondCallToAction
    );
  }
}
export default Header;
Header.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  image: PropTypes.object,
};

export const headerFragment = graphql`
  fragment HeaderFragment on ContentfulHeader {
    title
    subTitle
    image {
      fluid(maxHeight: 550) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    secondImage {
      fluid(maxWidth: 750) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    callToAction {
      ...CallToActionFragment
    }
    secondCallToAction {
      ...CallToActionFragment
    }
  }
`;
