import React from 'react';
import get from 'lodash/get';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import Link from './MyLink';
import { palette } from '../utils/style';
import BarLink from './BarLink';
import { buildTo } from '../utils/i18n';
import Color from 'color';
import { graphql } from 'gatsby';

const LinkInList = styled.div`
  padding: 10px 0;
  color: ${palette.text.secondary};
  font-weight: 600;
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${palette.text.primary};
  }
`;

const StyledBullet = styled.div`
  width: 6px;
  height: 20px;
  margin-right: 5px;
  margin-left: -5px;
  background-color: ${palette.customGray};
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;

  ${LinkInList}:hover & {
    background-color: ${props => props.color};
  }
`;

class LinksGroup extends React.PureComponent {
  state = {
    open: false,
  };

  _makeToggle = to => () =>
    this.setState({
      open: to,
    });

  renderGroupLink = (link, i, color) => {
    const title = get(link, 'title');
    const to = get(link, 'internalLink.path');
    return (
      <Link
        style={{ textDecoration: 'none', width: '100%' }}
        key={title}
        to={buildTo(to, this.props.locale)}
      >
        <LinkInList>
          <StyledBullet color={color} />
          {title}
        </LinkInList>
      </Link>
    );
  };

  render() {
    const id = get(this, 'props.contentful_id');
    const title = get(this.props, 'title');
    const links = get(this.props, 'links');
    // If it is the use case links
    const base = new Color(palette.primarySaturated);
    const colors =
      id === '5TEASPBz3OcYkOmiUaK2yS'
        ? [base.toString(), '#fd9d80', '#a8d37e']
        : [palette.customGray];

    return (
      <Tooltip
        position="bottom"
        trigger="click"
        interactive
        interactiveBorder={25}
        arrow={false}
        duration={110}
        useContext
        theme="light"
        onShow={this._makeToggle(true)}
        onHide={this._makeToggle(false)}
        distance={0}
        offset={0}
        html={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {links.map((link, i) =>
              this.renderGroupLink(link, i, get(colors, i % colors.length))
            )}
          </div>
        }
      >
        <BarLink
          title={title}
          style={{ color: this.state.open ? palette.primarySaturated : null }}
        />
      </Tooltip>
    );
  }
}
export default LinksGroup;
export const linksFragment = graphql`
  fragment LinksGroupFragment on ContentfulLinksGroup {
    contentful_id
    title
    links {
      ...LinkFragment
    }
  }
`;
