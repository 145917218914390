import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { media, palette } from '../utils/style';
import Customer from './Customer';
import get from 'lodash/get';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 8px;

  ${media.tablet`
    padding: 12px;
  `};
`;

const Title = styled.h4`
  font-size: 32px;
  line-height: 1.3em;
  font-weight: 500;
  color: ${palette.text.primary};
  text-align: center;
  max-width: 800px;
  margin-bottom: 10px;

  ${media.bigPhone`
    text-align: center;
      margin-bottom: 15px;
  `};
`;

const CustomersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  opacity: 0.72;
  margin: 32px 0;

  ${media.tablet`
      
      opacity: 0.54;
      justify-content: center;
  `};
  ${media.bigPhone`
    margin: 25px -50px 50px -50px;
  `};
`;

function CustomersBannerTwo({ title, customers }) {
  return (
    <Wrapper>
      <Title
        dangerouslySetInnerHTML={{
          __html: get(title, 'childMarkdownRemark.html'),
        }}
      />
      <CustomersWrapper>
        {customers.map(c => (
          <div style={{ padding: '5px 10px' }}>
            <Customer {...c} />
          </div>
        ))}
      </CustomersWrapper>
    </Wrapper>
  );
}
export default CustomersBannerTwo;
