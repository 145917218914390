import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import get from 'lodash/get';
import BlogFr from './blog.fr';
import BlogEn from './blog.en';
import Helmet from 'react-helmet';

export const withBlog = Component => {
  const hoc = React.forwardRef((props, ref) => {
    const lang = get(props, 'pageContext.locale', 'en');
    const Wrapper = lang === 'en' ? BlogEn : BlogFr;
    return (
      <Wrapper>
        <Helmet htmlAttributes={{ lang }} />
        <Component {...props} ref={ref} />
      </Wrapper>
    );
  });
  hoistNonReactStatics(hoc, Component);
  return hoc;
};
