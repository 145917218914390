import React from 'react';
import styled from 'styled-components';
import Link from './MyLink';
import { buildLinkTo } from '../utils/i18n';
import { palette } from '../utils/style';

export const LinkStyle = `
  text-decoration: none;
  color: ${palette.text.secondary};
  font-weight: 500;
  font-size: 1.2em;
  margin-right: 1.8vw;
  padding: 8px;
  transition: all 0.15s ease-in-out;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: ${palette.primarySaturated};
  }
`;

export const LinkA = styled(Link)`
  ${LinkStyle};
`;
export const LinkDiv = styled.div`
  ${LinkStyle};
`;

class BarLink extends React.PureComponent {
  render() {
    const { title, renderTitle, internalLink, external, locale } = this.props;
    const to = buildLinkTo({ external, internal: internalLink }, locale);
    return to ? (
      <LinkA to={to} style={this.props.style}>
        {renderTitle ? renderTitle() : title}
      </LinkA>
    ) : (
      <LinkDiv style={this.props.style}>
        {renderTitle ? renderTitle() : title}
      </LinkDiv>
    );
  }
}

export default BarLink;
