import React from 'react';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ArticlePreview from '../components/article-preview';
import SocialLinks from '../components/social-links';
import Author from '../components/Author';
import Category from '../components/Category';
import Features from '../components/Features';
import Waypoint from 'react-waypoint';
import { palette, media } from '../utils/style';
import { defineMessages, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/pro-regular-svg-icons';
import { faMinusSquare } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import { buildTo, buildLinkTo } from '../utils/i18n';
import MyLink from '../components/MyLink';
import { withBlog } from '../layouts/blog';
import { windowSafe } from '../utils/misc';

const messages = defineMessages({
  related: {
    id: 'article.related',
  },
});

const CallToAction = styled.p`
  font-size: 1.1em;
  color: ${palette.primary};
  font-weight: 600;
  padding: 1.5em;
  font-family: IBM Plex Serif, serif;

  &:hover {
    color: ${palette.primarySaturated};
  }
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 2.6em;
  margin-top: 0.2em;
  text-align: center;
  width: 120%;
  margin-left: -10%;
  margin-bottom: 0.5em;

  ${media.desktop`
   width: 100%;
   margin-left: 0;
  `};

  ${media.phone`
    font-size: 1.5em;
    margin-bottom: 0;
  `};
`;

const Content = styled.div`
  max-width: 780px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
`;
const BlogPost = styled.div`
  margin-top: 65px;
  & > * {
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.9em;
    font-family: IBM Plex Serif, serif;

    ${media.bigPhone`
      font-size: 1.1em;
      line-height: 1.8em;
    `};
  }

  & > p {
    margin: 20px 0;
  }

  & > p > a {
    color: ${palette.primary};
    text-decoration: none;
  }

  & > h2 {
    font-size: 2em;
    font-weight: normal;
  }

  & > h3 {
    font-size: 1.6em;
    font-weight: 500;
    line-height: 1.5em;

    ${media.bigPhone`
      font-size: 1.2em;
      font-weight: 600;
    `};
    ${media.phone`
      margin-bottom: 0;
    `};
  }

  & > blockquote {
    font-size: 1.4em;
    text-align: center;
    color: ${palette.text.primary};

    ${media.bigPhone`
      font-size: 1.1em;
    `};
  }

  & figcaption {
    text-align: center;
    font-size: 0.8em;
    font-style: italic;
  }
`;

const ReferencesContent = styled(BlogPost)`
  margin-top: 0;
  position: relative;
  border-bottom: 1px solid ${palette.lightGray};
`;

const ReferencesTitle = styled.h3`
  padding: 20px 0;
  margin: 0;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.01);
  }
`;

const RelatedWrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 8px;
`;

const RelatedTitle = styled.div`
  text-align: center;
  font-size: 1.1em;
  text-transform: uppercase;
  text-decoration: underline;
  color: ${palette.gray};
  margin-bottom: 1.5em;
  margin-top: 3em;
`;

const ReadTime = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1em;
  text-transform: uppercase;
`;

const SocialBar = styled.div`
  border-bottom: 1px solid ${palette.lightGray2};
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 26px;
  right: 10px;
  pointer-events: none;
`;

class References extends React.PureComponent {
  state = {
    open: false,
  };

  _toggle = () =>
    this.setState({
      open: !this.state.open,
    });

  render() {
    const { html } = this.props;
    if (!html) return null;

    return (
      <ReferencesContent>
        <ReferencesTitle onClick={this._toggle}>{'References'}</ReferencesTitle>
        <IconWrapper>
          <FontAwesomeIcon
            color={palette.primary}
            style={{ fontSize: '1.6em' }}
            icon={this.state.open ? faMinusSquare : faPlusSquare}
          />
        </IconWrapper>
        {!this.state.open ? null : (
          <div dangerouslySetInnerHTML={{ __html: html }} />
        )}
      </ReferencesContent>
    );
  }
}

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      social: false,
      absolute: false,
    };
  }

  renderHeader() {
    const post = get(this.props, 'data.contentfulBlogPostPage');
    const locale = get(this, 'props.pageContext.locale');
    const metaUrl = `https://keenoa.com${buildTo(
      `/blog/${post.slug}`,
      locale
    )}`;
    const metaTitle = `${post.title} | ${this.categoryName()}`;
    const metaImage = 'https:' + post.heroImage.file.url;
    const metaDescription = get(post, 'metaDescription.metaDescription');
    const body = get(post, 'body.body');
    // Apparently, those are the only schema supported properties: https://developers.google.com/search/docs/data-types/article#non-amp-sd

    return (
      <Helmet title={metaTitle}>
        <meta name="description" content={metaDescription} />
        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify([
            {
              '@context': 'http://schema.org',
              '@type': 'BlogPosting',
              headline: metaTitle,
              image: {
                '@type': 'ImageObject',
                url: metaImage,
              },
              articleBody: body,
              datePublished: post.publishDate,
            },
          ])}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:description" content={metaDescription} />
        <meta property="article:section" content={this.categoryName()} />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/keenoanutrition"
        />
        <meta property="article:published_time" content={post.publishDate} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {post.author.twitter ? (
          <meta name="twitter:creator" content={post.author.twitter} />
        ) : null}
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
      </Helmet>
    );
  }

  _handleCategoryEnter = () => {
    this.setState({
      social: false,
    });
  };
  _handleCategoryLeave = () => {
    this.setState({
      social: true,
    });
  };

  renderCallToAction = ({ title, internalLink, emailLink }, locale) => {
    return (
      <MyLink
        to={buildLinkTo({ internal: internalLink, email: emailLink }, locale)}
      >
        <CallToAction>
          {title}
          <FontAwesomeIcon
            color={palette.primarySaturated}
            style={{ marginLeft: 5 }}
            icon={faArrowRight}
          />
        </CallToAction>
      </MyLink>
    );
  };

  categoryName() {
    return get(this, 'props.data.contentfulBlogPostPage.categoryName');
  }

  render() {
    const locale = get(this, 'props.pageContext.locale');
    const post = get(this.props, 'data.contentfulBlogPostPage');
    const referencesHtml = get(post, 'references.childMarkdownRemark.html');
    const callToAction = get(post, 'callToAction');
    const features = get(post, 'features');

    return (
      <div>
        {this.renderHeader()}
        <Content>
          <SocialLinks
            postPath={windowSafe().location.pathname}
            inView={this.state.social}
            absolute={this.state.absolute}
          />
          <div
            style={{
              marginTop: 30,
              textAlign: 'center',
            }}
          >
            <Waypoint
              onEnter={this._handleCategoryEnter}
              onLeave={this._handleCategoryLeave}
            />
            <Category
              category={this.categoryName()}
              readTime={post.body.childMarkdownRemark.timeToRead}
              style={{ fontSize: '0.8em' }}
            />
          </div>
          <Title>{post.title}</Title>
          <Author {...post.author} locale={locale} />
          <BlogPost
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
          <Features features={features} locale={locale} />
          {callToAction && this.renderCallToAction(callToAction, locale)}
          <SocialBar />
          <References html={referencesHtml} />
        </Content>
        <div>
          <RelatedTitle>
            <FormattedMessage {...messages.related} />
          </RelatedTitle>
          <Waypoint
            onEnter={this._handleCategoryEnter}
            onLeave={this._handleCategoryLeave}
            bottomOffset={'20%'}
            topOffset={'-500px'}
          />
          <RelatedWrapper>
            {post.related.map(preview => (
              <ArticlePreview key={preview.id} article={preview} />
            ))}
          </RelatedWrapper>
        </div>
      </div>
    );
  }
}

export default withBlog(BlogPostTemplate);

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    contentfulBlogPostPage(id: { eq: $id }) {
      title
      slug
      metaDescription {
        metaDescription
      }
      author {
        ...AuthorPreviewFragment
      }
      categoryName
      callToAction {
        ...LinkFragment
      }
      publishDate
      heroImage {
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
        body
      }
      features {
        ...FeatureFragment
      }
      references {
        childMarkdownRemark {
          html
        }
      }
      related {
        ...ArticlePreviewFragment
      }
    }
  }
`;
