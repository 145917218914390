import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Benefit from '../components/Benefit';
import Meta from '../components/Meta';
import Header from '../components/Header';
import Spacer from '../components/Spacer';
import PageFooter from '../components/PageFooter';
import { withHome } from '../layouts/home';

class UseCase extends React.PureComponent {
  render() {
    const data = get(this, 'props.data.contentfulUseCasePage');

    const meta = get(data, 'meta');
    const header = get(data, 'header');
    const benefits = get(data, 'benefits') || [];
    const pageFooter = get(data, 'pageFooter');

    return (
      <div>
        <Meta {...meta} />
        <Header {...header} />
        <Spacer lg />
        {benefits.map((benefit, i) => (
          <>
            <Benefit
              key={i}
              {...benefit}
              image_side={i % 2 !== 0 ? 'left' : 'right'}
            />
            <Spacer lg />
          </>
        ))}
        {pageFooter ? <PageFooter {...pageFooter} /> : null}
      </div>
    );
  }
}
export default withHome(UseCase);

export const pageQuery = graphql`
  query UseCasePageQuery($id: String!) {
    contentfulUseCasePage(id: { eq: $id }) {
      header {
        ...HeaderFragment
      }
      meta {
        ...MetaFragment
      }
      benefits {
        ...BenefitFragment
      }
      pageFooter {
        ...PageFooterFragment
      }
    }
  }
`;
