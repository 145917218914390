import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { palette } from '../utils/style';
import Header from '../components/Header';
import Meta from '../components/Meta';
import Plan from '../components/Plan';
import QnA from '../components/QnA';
import PageFooter from '../components/PageFooter';
import { graphql } from 'gatsby';
import { defineMessages, FormattedMessage } from 'react-intl';
import { withHome } from '../layouts/home';

const messages = defineMessages({
  faq: { id: 'pricing.faq' },
});

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const FaqHeader = styled.h2`
  font-size: 2.5em;
  line-height: 1.2em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 350px;
  padding: 20px;
  color: ${palette.text.primary};
`;

const PLANS = {
  en: [
    {
      title: 'Dietitian',
      price: 30,
      priceDetail: 'Dietitian / Month',
      name: null,
      features: [
        'Free 14-day trial',
        'Unlimited number of clients',
        'Free mobile application for your clients',
      ],
      callToAction: 'Get started for free',
    },
    {
      title: 'Research',
      price: null,
      priceDetail: null,
      name: 'Collect in-depth dietary data with the user-friendly Keenoa app',
      features: ['Validated in research', 'Highest security and privacy'],
      callToAction: 'Contact us',
    },
  ],
  fr: [
    {
      title: 'Nutritionniste',
      price: 30,
      priceDetail: 'Nutritionniste / Mois',
      name: null,
      features: [
        'Essai gratuit de 14 jours',
        'Nombre illimité de clients',
        'Application mobile gratuite pour vos clients',
      ],
      callToAction: 'Débutez gratuitement',
    },
    {
      title: 'Research',
      price: null,
      priceDetail: null,
      name: 'Collect in-depth dietary data with the user-friendly Keenoa app',
      features: ['Validated in research', 'Highest security and privacy'],
      callToAction: 'Contact us',
    },
  ],
};

const QnAs = {
  en: [
    {
      question: 'Will my client be charged to use the mobile app?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>No. The app is available for free in the AppStore and PlayStore. However, only you have access to the nutrient analysis, which means that they will get value from the app only by meeting with you.</p>',
        },
      },
    },
    {
      question: 'Do I have to be a Registered Dietitian?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>Yes. We built Keenoa <strong>excusively for dietitians</strong>. This tool provides professionals with a lot of information that can and should only be accessed by university trained professionnals in nutrition. If you are not a dietitian and think your client could benefit from the Keenoa app, please contact us so we can introduce you to a dietitian who is using Keenoa.</p>',
        },
      },
    },
  ],
  fr: [
    {
      question: "Est-ce-qu'il y a des coûts pour mon client ?",
      answer: {
        childMarkdownRemark: {
          html:
            "<p>Non. L'application mobile est disponible gratuitement sur le AppStore et PlayStore. Par contre, seulement vous avez accès à l'information nutritionnelle. Ceci implique que l'app est utile pour votre client seulement s'il vous consulte.</p>",
        },
      },
    },
    {
      question: 'Est-ce-que je dois être nutritionniste ?',
      answer: {
        childMarkdownRemark: {
          html:
            "<p>Oui. Keenoa est concu <strong>exclusivement pour les nutritionnistes</strong>. Lors de votre inscription, nous vous demandons votre numéro de licence pour effectuer la validation. Si vous n'êtes pas un nutritionniste et vous croyez que vos clients pourraient bénéficier de l'app Keenoa, veuillez nous contacter pour qu'on puisse vous mettre en contact avec un nutritionniste abonné à Keenoa.</p>",
        },
      },
    },
  ],
};

class Pricing extends React.Component {
  render() {
    const data = get(this, 'props.data.contentfulPricingPage');
    const header = get(data, 'header');
    const meta = get(data, 'meta');
    const pageFooter = get(data, 'pageFooter');
    const locale = get(data, 'node_locale');
    const plans = PLANS[locale];
    const faq = QnAs[locale];

    return (
      <div>
        <Meta {...meta} />
        <Header {...header} />
        <CardsWrapper>
          {plans.map((p, i) => (
            <Plan key={i} {...p} locale={locale} />
          ))}
        </CardsWrapper>
        <FaqHeader>
          <FormattedMessage {...messages.faq} />
        </FaqHeader>
        {faq.map((f, i) => (
          <QnA key={i} {...f} />
        ))}
        <PageFooter {...pageFooter} />
      </div>
    );
  }
}

export default withHome(Pricing);

export const pageQuery = graphql`
  query PricingPageQuery($id: String!) {
    contentfulPricingPage(id: { eq: $id }) {
      node_locale
      header {
        ...HeaderFragment
      }
      meta {
        ...MetaFragment
      }
      pageFooter {
        ...PageFooterFragment
      }
    }
  }
`;
