import React from 'react';
import Cards from './cards';
import ArticlePreview from './article-preview';
import get from 'lodash/get';

class PostListing extends React.PureComponent {
  render() {
    const posts = get(this, 'props.posts') || [];
    return (
      <Cards>
        {posts.map((post, i) => {
          const node = post.node ? post.node : post;
          return (
            <ArticlePreview key={node.slug} article={node} first={i === 0} />
          );
        })}
      </Cards>
    );
  }
}

export default PostListing;
