import React from 'react';
import styled from 'styled-components';
import { shadow, palette, media } from '../utils/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FEATURE_FLAGS } from '../constants/featureFlags';

const Bar = styled.div`
  position: fixed;
  height: 62px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  right: 0;
  top: ${x => (!x.blog && FEATURE_FLAGS.BANNER_ENABLED ? 40 : 0)}px;
  z-index: 500;
  background-color: white;
  opacity: 0.98;

  ${media.bigPhone`
    top: ${x => (!x.blog && FEATURE_FLAGS.BANNER_ENABLED ? 60 : 0)}px;
  `};
`;

const MobileTopWrapper = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `};
`;

const MobileWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  z-index: ${props => (props.visible ? 1 : -1)};
  padding-top: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: ${shadow(6)};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 0.195s ease-in-out;
  transform-origin: top right;
  transform: scale(${props => (props.visible ? 1 : 0)});
`;

const BarsIcon = styled.div`
  height: 40px;
  width: 40px;
  margin-right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  color: ${palette.text.secondary};
`;
const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.35s ease-in-out;
  transform: rotate(${props => (props.open ? 90 : 0)}deg);
  color: ${palette.text.secondary};
`;

class MobileNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  _toggleMenu = e => {
    e.stopPropagation();
    this.setState({
      open: !this.state.open,
    });
  };

  renderPopup = () => {
    const { renderMenu } = this.props;
    return (
      <MobileWrapper visible={this.state.open}>
        <CloseIcon onClick={this._toggleMenu} open={this.state.open}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseIcon>
        <div>{renderMenu(this._toggleMenu)}</div>
      </MobileWrapper>
    );
  };

  renderBurgerIcon = () => {
    return (
      <div
        onClick={this._toggleMenu}
        style={{
          height: '100%',
          width: 78,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BarsIcon>
          <FontAwesomeIcon icon={faBars} />
        </BarsIcon>
      </div>
    );
  };

  renderOverlay = () => {
    if (!this.state.open) return null;

    return (
      <div
        onClick={this._toggleMenu}
        style={{
          zIndex: 5,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    );
  };

  render() {
    return (
      <MobileTopWrapper>
        <Bar blog={this.props.blog}>
          {this.props.renderLogo()}
          {!this.props.blog && this.renderBurgerIcon()}
          {this.renderPopup(this.props.isDietitian)}
        </Bar>
        {this.renderOverlay()}
      </MobileTopWrapper>
    );
  }
}

export default MobileNav;
