import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

const MyLink = ({ children, to, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use gatsby for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink style={{ textDecoration: 'none' }} to={to} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} style={{ textDecoration: 'none' }} {...other}>
      {children}
    </a>
  );
};

export default MyLink;
