import { insecureFetch, json, JSON_HEADER } from '../utils/api';
import { get as storageGet, put as storagePut } from '../utils/storage';
import { getUrlParameter, windowSafe } from '../utils/misc';
import { navigate } from 'gatsby';
import { buildTo } from '../utils/i18n';
const uuidv4 = require('uuid/v4');

const currentLocale = () => {
  const pathname = windowSafe().location.pathname;
  return pathname.startsWith('/fr') ? 'fr' : 'en';
};

const _getId = () => {
  let id = storageGet('id');
  if (!id) {
    id = uuidv4();
    storagePut('id', id);
  }
  return id;
};
/** Singleton that we keep updating */
let prospect;
let updateLocale = false;
export const getProspect = () => {
  if (prospect) {
    if (prospect.locale !== currentLocale()) {
      prospect.locale = currentLocale();
      updateLocale = true;
    }
    return prospect;
  }

  prospect = {
    id: _getId(),
    email: undefined,
    requested_on: undefined,
    address: undefined,
    form: undefined,
    locale: undefined,
    client_id: undefined,
  };

  return prospect;
};

/////////////////////////////////////////

const _receivePropsect = res =>
  json(res).then(receivedProspect => {
    updateLocale = false;
    prospect = { ...prospect, ...receivedProspect };
    return prospect;
  });

const _updateProspect = updateObject =>
  insecureFetch(`/prospect-clients/${getProspect().id}`, {
    method: 'put',
    headers: JSON_HEADER,
    body: JSON.stringify(
      !updateLocale
        ? updateObject
        : { ...updateObject, locale: currentLocale() }
    ),
  }).then(_receivePropsect);

const _submitRequest = () =>
  insecureFetch(`/prospect-clients/${getProspect().id}/submit-request`, {
    method: 'post',
    headers: JSON_HEADER,
  }).then(_receivePropsect);

/** FORM UPDATES */
export const setGoal = goal => {
  return _updateProspect({ form: { ...getProspect().form, goal } });
};

export const setMotivation = motivation => {
  return _updateProspect({ form: { ...getProspect().form, motivation } });
};

export const setHistory = history => {
  return _updateProspect({ form: { ...getProspect().form, history } });
};

export const setComment = comment => {
  return _updateProspect({ form: { ...getProspect().form, comment } });
};

export const setEmail = email => {
  return _updateProspect({ email });
};

/** ADDRESS UPDATE */
export const setAddress = address => {
  return _updateProspect({ address });
};

/** CLIENT ID UDPATE */
export const setClientId = clientId => {
  return _updateProspect({ client_id: clientId });
};

export const submitRequest = comment => _submitRequest(comment);
