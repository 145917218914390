import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { palette, media } from '../utils/style';
import times from 'lodash/times';

const Wrapper = styled.div`
  font-family: Roboto;

  margin-top: -${x => (x.i > 0 ? 10 : 0)}px;
  margin-${x => (x.i % 2 === 0 ? 'right' : 'left')}: auto;

  max-width: 563px;

  display: flex;
  flex-direction: row;
  color: ${palette.text.midPrimary};

  ${media.tablet`
    margin: 10px auto;
  `};
`;

const Name = styled.div`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 600;
  font-weight: 500;

  ${media.phone`
    margin-top: 24px;
    font-size: 14px;
  `};
`;
const Star = styled(FontAwesomeIcon)`
  font-size: 10px;
  margin: 0 2px;
`;
const StarRow = styled.div`
  font-size: 10px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`;
const ReviewText = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const ContentWrapper = styled.div`
  padding-left: 9px;
`;

function Review({ reviewerName, rating, review, reviewTime, photo, i }) {
  return (
    <Wrapper i={i}>
      <div style={{ marginTop: 10 }}>
        <Img fixed={photo.fixed} style={{ borderRadius: '50%' }} />
      </div>
      <ContentWrapper>
        <Name>{reviewerName}</Name>
        <StarRow>
          {times(rating, i => (
            <Star icon={faStar} />
          ))}
        </StarRow>
        <ReviewText>{review}</ReviewText>
      </ContentWrapper>
    </Wrapper>
  );
}
export default Review;

export const ReviewFragment = graphql`
  fragment ReviewFragment on ContentfulReview {
    reviewerName
    rating
    review
    reviewTime
    photo {
      title
      fixed(height: 48, width: 48, resizingBehavior: THUMB) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
  }
`;
