import { windowSafe } from './misc';

export function put(key, value) {
  windowSafe().localStorage.setItem(key, value);
}

export function get(key) {
  return windowSafe().localStorage.getItem(key);
}

export function remove(key) {
  return windowSafe().localStorage.removeItem(key);
}

export function clear() {
  windowSafe().localStorage.clear();
}

export default {
  put,
  get,
  remove,
  clear,
};
