import React from 'react';
import Alternative from './Alternative';
import { TabBar, Tab } from './Tabs';
import Spacer from './Spacer';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import { palette } from '../utils/style';

const AlternativesWrapper = styled.div`
  --mdc-theme-primary: ${palette.primary};

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

function Alternatives({ alternatives }) {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <>
      <Spacer />
      <AlternativesWrapper>
        <TabBar
          activeTabIndex={activeTab}
          onActivate={evt => setActiveTab(evt.detail.index)}
          className="kmdc_tabBar_compact"
        >
          {alternatives.map(({ title }) => (
            <Tab restrictIndicator>{title}</Tab>
          ))}
        </TabBar>
      </AlternativesWrapper>
      <SwipeableViews index={activeTab} onChangeIndex={i => setActiveTab(i)}>
        {alternatives.map(a => (
          <Alternative {...a} />
        ))}
      </SwipeableViews>
    </>
  );
}

export default Alternatives;
