import React from 'react';
import get from 'lodash/get';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { palette, media } from '../utils/style';
import CallToAction from './CallToAction';
import { graphql } from 'gatsby';

const Wrapper = styled.div`
  text-align: center;
  padding: 5vw 0;
  position: relative;
  margin-top: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.tablet`
    padding: 14vw 0;
  `};
`;

const Headline = styled.h3`
  max-width: 600px;
  font-size: 2.5em;
  line-height: 1.2em;

  color: ${palette.text.primary};
  margin: 0;

  ${media.tablet`
    font-size: 2.2em;
  `};
  ${media.bigPhone`
    font-size: 1.6em;
  `};
`;

class PageFooter extends React.PureComponent {
  render() {
    const headline = get(this, 'props.headline');
    const callToAction = get(this, 'props.callToAction');
    const imageSizes = get(this, 'props.background.fluid', undefined);

    return (
      <Wrapper>
        <Headline>{headline}</Headline>
        <CallToAction {...callToAction} accent />
        {!imageSizes ? null : (
          <div style={{ zIndex: -1 }}>
            <Img
              style={{
                position: 'absolute',
                left: 0,
                top: -10,
                width: '100%',
                zIndex: -1,
                bottom: -10,
              }}
              fluid={imageSizes}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}
export default PageFooter;

export const pageFooterFragment = graphql`
  fragment PageFooterFragment on ContentfulPageFooter {
    headline
    callToAction {
      ...CallToActionFragment
    }
    background {
      fluid(maxWidth: 1250) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
