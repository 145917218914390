import React from 'react';
import get from 'lodash/get';

import Meta from '../components/Meta';
import Header from '../components/Header';
import Benefit from '../components/Benefit';
import PageFooter from '../components/PageFooter';

import { graphql } from 'gatsby';
import { withHome } from '../layouts/home';

class How extends React.Component {
  render() {
    const data = get(this, 'props.data.contentfulHowItWorksPage');
    const header = get(data, 'header');
    const meta = get(data, 'meta');
    const benefits = get(data, 'steps');
    const pageFooter = get(data, 'pageFooter');

    return (
      <div>
        <Meta {...meta} />
        <Header {...header} />
        {benefits.map((benefit, i) => (
          <Benefit
            key={i}
            {...benefit}
            image_side={i % 2 !== 0 ? 'left' : 'right'}
          />
        ))}
        <div style={{ marginTop: '-80px' }}>
          <PageFooter {...pageFooter} />
        </div>
      </div>
    );
  }
}

export default withHome(How);

export const pageQuery = graphql`
  query HowPageQuery($id: String!) {
    contentfulHowItWorksPage(id: { eq: $id }) {
      id
      header {
        ...HeaderFragment
      }
      meta {
        ...MetaFragment
      }
      steps {
        ...BenefitFragment
      }
      pageFooter {
        ...PageFooterFragment
      }
    }
  }
`;
