import React, { Component, ReactDOM } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import styled from 'styled-components';

const IconWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;
const LinksWrapper = styled.div`
  position: fixed;
  top: 200px;
  left: -350px;
  transition: opacity 0.1s ease-in-out;
  transform: translateX(40vw);
`;

class SocialLinks extends Component {
  render() {
    const { postPath, inView } = this.props;
    const url = 'https://keenoa.com' + postPath;
    const iconSize = 36;

    return (
      <LinksWrapper
        style={{ opacity: inView ? 1 : 0 }}
        ref={x => (this.rect = x)}
      >
        <IconWrapper>
          <TwitterShareButton url={url}>
            <TwitterIcon round size={iconSize} />
          </TwitterShareButton>
        </IconWrapper>
        <IconWrapper>
          <FacebookShareButton url={url}>
            <FacebookIcon round size={iconSize} />
          </FacebookShareButton>
        </IconWrapper>
        <IconWrapper>
          <LinkedinShareButton url={url}>
            <LinkedinIcon round size={iconSize} />
          </LinkedinShareButton>
        </IconWrapper>
      </LinksWrapper>
    );
  }
}

export default SocialLinks;
