import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { media, palette } from '../utils/style';
import { Link } from 'gatsby';
import { buildTo } from '../utils/i18n';

const messages = defineMessages({
  terms: {
    id: 'policy.terms.link',
  },
  privacy: {
    id: 'policy.privacy.link',
  },
  subscription: {
    id: 'policy.subscription.link',
  },
});

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;

  ${media.tablet`
  margin-top: 60px;
  justify-content: space-between;
`};
`;
const LinkWrapper = styled(Link)`
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.midGray};
  color: white;
  border-radius: 5px;
  margin-bottom: 20px;
  text-decoration: none;

  transition: box-shadow -webkit-text-stroke 0.15s ease-in-out;

  ${media.tablet`
  height: 40px;
  width: 40px;
  overflow: visible;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 25px;
`};

  &:hover {
    filter: contrast(1.1);
    cursor: pointer;
  }
`;

const StyledLink = styled.span`
  color: white;
  text-decoration: none;
  font-size: 1em;
  font-weight: 600;

  ${media.tablet`
  margin-top: -70px;
  color: rgba(0,0,0,0.87);
`};
`;

export const LINKS = [
  {
    id: '/policy/terms',
    name: <FormattedMessage {...messages.terms} />,
  },
  {
    id: '/policy/privacy',
    name: <FormattedMessage {...messages.privacy} />,
  },
  {
    id: '/policy/subscription',
    name: <FormattedMessage {...messages.subscription} />,
  },
];

const PolicyLinks = ({ locale }) => {
  return (
    <LinksWrapper>
      {LINKS.map(link => (
        <LinkWrapper
          key={link.id}
          to={buildTo(link.id, locale)}
          activeStyle={{ backgroundColor: palette.primarySaturated }}
        >
          <StyledLink>{link.name}</StyledLink>
        </LinkWrapper>
      ))}
    </LinksWrapper>
  );
};

export default PolicyLinks;
