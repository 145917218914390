import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import { palette, media } from '../utils/style';

const Line = styled.div`
  position: relative;
  padding-bottom: 0;
  max-width: 30em;
  margin-bottom: 20px;
  width: fit-content;

  font-size: 24px;

  ${media.tablet`
    font-size: 18px;

  `};
  ${media.phone`
    font-size: 16px;
  `};
`;
const Img2 = styled(Img)`
  height: 100%;
`;

const ImageWrapper = styled.div`
  flex: 1;
  max-height: 425px;
  overflow: hidden;

  ${media.phone`
    margin-right: -75px;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 24px;
  overflow: hidden;

  ${media.bigPhone`
    margin-top: 15px;
    padding: 12px;
  `};
`;

function Alternative({ benefits, inconvenients, photo }) {
  const renderLine = (text, isBenefit) => (
    <Line>
      <FontAwesomeIcon
        icon={isBenefit ? faThumbsUp : faThumbsDown}
        color={isBenefit ? palette.success : palette.error}
        style={{ marginRight: 8 }}
      />
      <span>{text}</span>
    </Line>
  );
  return (
    <Wrapper>
      <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
        <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
          {benefits.map(t => renderLine(t, true))}
          {inconvenients.map(t => renderLine(t, false))}
        </div>
      </div>
      <ImageWrapper>
        <Img2
          fluid={photo.fluid}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'top left',
          }}
        />
      </ImageWrapper>
    </Wrapper>
  );
}
export default Alternative;

export const AlternativeFragment = graphql`
  fragment AlternativeFragment on ContentfulAlternative {
    title
    benefits
    inconvenients
    photo {
      fluid(maxWidth: 450) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
