import React from 'react';
import get from 'lodash/get';
import PostListing from '../components/post-listing';
import ArticleShowcase from '../components/article-showcase';
import head from 'lodash/head';
import Meta from '../components/Meta';
import { graphql } from 'gatsby';
import { withBlog } from '../layouts/blog';

class BlogIndex extends React.Component {
  renderComingSoon() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          maxWidth: 600,
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <h3>{'Coming Soon'}</h3>
        <p>
          {
            'We are working with nutrition researchers, across the continent to present you the latest research in an easy to read format'
          }
        </p>
      </div>
    );
  }

  render() {
    const posts = get(
      this,
      'props.data.allContentfulBlogPostPage.edges'
    ).filter(x => {
      return x.node.node_locale === x.node.locale;
    });

    if (!posts || !posts.length) {
      return this.renderComingSoon();
    }
    const meta = get(this, 'props.data.contentfulMeta');
    return (
      <div>
        <Meta {...meta} />
        <ArticleShowcase article={head(posts)} />
        <div style={{ height: 30 }} />
        <PostListing posts={posts} />
      </div>
    );
  }
}

export default withBlog(BlogIndex);

export const pageQuery = graphql`
  query BlogIndexQuery($locale: String!) {
    allContentfulBlogPostPage(
      # filter: { locale: { eq: $locale }, node_locale: { eq: $locale } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          node_locale
          ...ArticlePreviewFragment
        }
      }
    }
    contentfulMeta(
      id: { glob: "c5t7SEgYcX6UQYieKY4WQkE*" }
      node_locale: { eq: $locale }
    ) {
      ...MetaFragment
    }
  }
`;
