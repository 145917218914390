import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { media, palette } from '../utils/style';
import Argument from './Argument';
import CallToAction from './CallToAction';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin: -20px auto;

  ${media.tablet`
    padding: 12px;
    background-color: white;
  `};
`;

function FinalArguments(props) {
  return (
    <Wrapper>
      {props.arguments.map(argument => (
        <Argument {...argument} accentuate large />
      ))}
      <div style={{ margin: 'auto', marginTop: 25 }}>
        <CallToAction {...props.callToAction} />
      </div>
    </Wrapper>
  );
}
export default FinalArguments;
