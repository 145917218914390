import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import WhiteFrame from './whiteframe';
import get from 'lodash/get';
import { buildTo } from '../utils/i18n';
import Author from './Author';
import Category from './Category';
import { media } from '../utils/style';

const ImageWrapper = styled.div`
  height: 100vh;
  max-height: 600px;
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
`;
const Container = styled.div`
  height: 100vh;
  max-height: 600px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;

  ${media.phone`
    display: none;
  `};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: space-between;
  z-index: 2;
`;

const Title = styled.h3`
  text-decoration: none;
  margin: 10px 0 0 0;
  font-weight: 600;
  line-height: 1.2em;
  font-size: 2.8em;
  margin-bottom: 0.8em;
`;

const Description = styled.p`
  margin: 0;
  font-size: 1.2em;
  font-weight: 400;
  font-family: IBM Plex Serif, serif;

  ${media.phone`
    display: none;
  `};
`;

const MyFrame = styled(WhiteFrame)`
  margin-left: auto;
  margin-right: auto;
  margin-top: -14em;
  padding: 1.2em;
`;

const MyImg = styled(Img)`
  height: 100vh;
  max-height: 600px;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: center center !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;'; // needed for IE9+ polyfill
  }
`;
class Showcase extends React.Component {
  renderFrame = () => {
    const { article, media } = this.props;
    const category = get(article, 'node.category.name');
    const readTime = get(article, 'node.body.childMarkdownRemark.timeToRead');
    const excerpt = get(article, 'node.body.childMarkdownRemark.excerpt');
    const author = get(article, 'node.author');
    const locale = get(article, 'node.locale');
    return (
      <MyFrame level={3}>
        <TextWrapper>
          <Category category={category} readTime={readTime} />
          <Title>{article.node.title}</Title>
          <Description>{excerpt}</Description>
          <Author {...author} locale={locale} />
        </TextWrapper>
      </MyFrame>
    );
  };

  _navigateToArticle = () => {
    const { article } = this.props;
    navigate(buildTo(`/blog/${article.node.slug}`, article.node.locale));
  };

  render() {
    const { article } = this.props;
    return (
      <Wrapper onClick={this._navigateToArticle}>
        <Container>
          <ImageWrapper>
            <MyImg fluid={article.node.heroImage.fluid} />
          </ImageWrapper>
        </Container>
        {this.renderFrame()}
      </Wrapper>
    );
  }
}

export default Showcase;
