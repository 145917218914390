import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';
import { palette, media, useMedia } from '../utils/style';
import WhiteFrame from './whiteframe';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { buildTo } from '../utils/i18n';
import Color from 'color';
import { defineMessages, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons';

const messages = defineMessages({
  learnMore: { id: 'home.learn' },
});

const Wrapper = styled.div`
  max-width: 33%;

  flex: 0 0 auto;
  display: flex;
  padding: 0;

  border-radius: 8px;

  flex-direction: column;

  ${media.bigPhone`
    max-width: 100%;
    margin-top: 50px;
  `};
`;

const ImgWrapper = styled.div`
  height: 250px;
  transition: all 0.25s ease-in-out;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 15px;
  position: relative;
  transition: all 0.25s ease-in-out;
`;

const Title = styled.h3`
  font-size: 18px;
  line-height: 1.4em;
  font-weight: 900;
  margin: 0;
  margin-bottom: 0.5em;
  color: ${palette.text.primary};

  ${media.desktop`
    font-size: 16px;
  `};

  ${media.tablet`
    font-size: 14px;
  `};

  ${media.bigPhone`
    font-size: 18px;
  `};
`;
const SubTitle = styled.h4`
  font-size: 22px;
  font-weight: 300;
  color: ${palette.text.primary};
  margin: 0;

  ${media.desktop`
    font-size: 18px;
  `};

  ${media.tablet`
    font-size: 16px;
  `};

  ${media.bigPhone`
    font-size: 22px;

  `};
`;

const Learn = styled.span`
  border-bottom: 2px solid white;
  color: ${palette.primarySaturated};
  font-weight: 800;
  transition: all 0.15s ease-in-out;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  width: fit-content;

  ${media.tablet`
    margin-top: 1em;
  `};

  &:hover {
    border-color: ${palette.primarySaturated};
    cursor: pointer;
  }
`;
const LearnIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  margin-left: 0.5em;
  transition: all 0.15s ease-in-out;

  ${Learn}:hover & {
    margin-left: 0.6em;
  }
`;

export default function UseCaseCard(props) {
  const _openUseCase = () => {
    const path = get(props, 'meta.path');
    const locale = get(props, 'node_locale');
    navigate(buildTo(path, locale));
  };

  const renderImage = () => {
    const image = get(props, 'header.image');
    return (
      <ImgWrapper>
        {!image ? (
          <div style={{ flex: 1 }} />
        ) : (
          <Img
            style={{ height: '100%' }}
            fluid={image.fluid}
            outerWrapperClassName="benefit-image"
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        )}
      </ImgWrapper>
    );
  };

  const renderLearnMore = () => {
    return (
      <div>
        <Learn>
          <FormattedMessage {...messages.learnMore} />
          <LearnIcon icon={faLongArrowAltRight} />
        </Learn>
      </div>
    );
  };

  const job = get(props, 'job');
  const testimonialContent = get(props, 'testimonial.content');
  const subtitle = get(props, 'header.subTitle');

  return (
    <Wrapper left={!(props.i % 2)}>
      {renderImage()}
      <ContentWrapper level={3} onClick={_openUseCase}>
        <Title>{job}</Title>
        {testimonialContent ? (
          <SubTitle
            dangerouslySetInnerHTML={{
              __html: get(testimonialContent, 'childMarkdownRemark.html'),
            }}
          />
        ) : (
          <SubTitle>{subtitle}</SubTitle>
        )}
        {renderLearnMore()}
      </ContentWrapper>
    </Wrapper>
  );
}

export const useCaseFragment = graphql`
  fragment UseCaseCardFragment on ContentfulUseCasePage {
    header {
      title
      subTitle
      image {
        fluid(maxHeight: 250) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    job
    meta {
      path
    }
    node_locale
    testimonial {
      content {
        childMarkdownRemark {
          html
        }
      }
      name
      photo {
        title
        fixed(height: 100, width: 100, resizingBehavior: THUMB) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
    }
  }
`;
