import React from 'react';
import styled from 'styled-components';
import { palette } from '../utils/style';
import { buildTo } from '../utils/i18n';
import Img from 'gatsby-image';
import { navigate, graphql } from 'gatsby';

const AuthorCompany = styled.p`
  margin: 0;
  position: relative;
  top: -5px;
  color: ${palette.text.tertiary};
`;
const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8em;
  margin-top: 2em;
  cursor: pointer;
`;

const AuthorName = styled.p`
  margin: 0;
  padding-top: 5px;

  ${AuthorWrapper}:hover & {
    color: ${palette.primarySaturated};
`;

const ProfilePictureWrapper = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 0.6em;
  & > div {
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px;
  }
`;

class Author extends React.Component {
  _navigateToAuthor = e => {
    e.stopPropagation();
    const { slug, locale } = this.props;
    navigate(buildTo(`/blog/author/${slug}`, locale));
  };
  render() {
    const { name, image, title, company } = this.props;
    return (
      <div onClick={this._navigateToAuthor}>
        <AuthorWrapper>
          <ProfilePictureWrapper>
            <Img fixed={image.fixed} />
          </ProfilePictureWrapper>
          <div>
            <AuthorName>{name}</AuthorName>
            <AuthorCompany>{`${title || ''} ${company || ''}`}</AuthorCompany>
          </div>
        </AuthorWrapper>
      </div>
    );
  }
}
export default Author;

export const authorPreviewFragment = graphql`
  fragment AuthorPreviewFragment on ContentfulPerson {
    name
    title
    slug
    company
    image {
      fixed(height: 50, width: 50, resizingBehavior: THUMB) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
  }
`;
