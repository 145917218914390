import React from 'react';
import Container from '../../components/container';
import { IntlProvider } from 'react-intl';
import Notifications from 'react-notify-toast';
import base from '../base.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;

class Template extends React.Component {
  render() {
    const { children, locale, messages } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <IntlProvider locale={locale} messages={messages}>
          <Container>
            <Notifications />
            {children}
          </Container>
        </IntlProvider>
      </div>
    );
  }
}

export default Template;
