import React from 'react';
import styled from 'styled-components';
import { media, palette } from '../utils/style';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';

const ArgumentPoint = styled.div`
  font-size: ${x => (x.large ? 24 : 16)}px;
  font-weight: 500;
  position: relative;
  color: ${palette.text.primary};

  ${media.tablet`
    width: 90%;
  `};
  ${media.phone`
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  `};
`;

const Bullet = styled.div`
  position: absolute;
  top: 10px;
  left: 25px;
`;

const Arg = styled.div`
  position: relative;
  padding-bottom: 0;
  max-width: ${x => (x.extend ? 40 : 30)}em;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
function Argument({ text, id, accentuate, extend }) {
  return (
    <Arg key={id} style={{ paddingLeft: accentuate ? 0 : 45 }} extend>
      {!accentuate ? (
        <Bullet>
          <div
            style={{
              height: 5,
              width: 5,
              borderRadius: 5,
              background: palette.primary,
            }}
          />
        </Bullet>
      ) : (
        <span style={{ marginRight: 8, fontSize: 24 }}>
          <FontAwesomeIcon icon={faCheckCircle} color={palette.success} />
        </span>
      )}
      <ArgumentPoint
        dangerouslySetInnerHTML={{
          __html: get(text, 'childMarkdownRemark.html'),
        }}
        large={!!accentuate}
      />
    </Arg>
  );
}
export default Argument;

export const TextFragment = graphql`
  fragment TextFragment on ContentfulText {
    text {
      id
      childMarkdownRemark {
        html
      }
    }
  }
`;

export const argumentFragment = graphql`
  fragment ArgumentFragment on ContentfulText {
    ...TextFragment
  }
`;
