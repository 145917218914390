import React from 'react';
import Container from '../../components/container';
import Navigation from '../../components/navigation';
import Notifications from 'react-notify-toast';
import { IntlProvider } from 'react-intl';
import Waypoint from 'react-waypoint';
import SiteMap from '../../components/Sitemap';
import Footer from '../../components/Footer';
import base from '../base.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }
  _handleEnter = () => {
    this.setState({
      scrolled: false,
    });
  };
  _handleLeave = () => {
    this.setState({
      scrolled: true,
    });
  };
  render() {
    const { children, data, locale, messages } = this.props;
    return (
      <IntlProvider locale={locale} messages={messages}>
        <Container>
          <Notifications />
          <Waypoint onEnter={this._handleEnter} onLeave={this._handleLeave}>
            <div
              style={{
                height: 100,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          </Waypoint>
          <Navigation
            data={data}
            locale={locale}
            scrolled={this.state.scrolled}
          />
          {children}
          <SiteMap locale={locale} data={data} />
          <Footer locale={locale} />
        </Container>
      </IntlProvider>
    );
  }
}

export default Template;
