import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { media, palette } from '../utils/style';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  terms: {
    id: 'footer.terms',
  },
  privacy: {
    id: 'footer.privacy',
  },
});

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 20px;
  color: ${palette.text.secondary};

  ${media.phone`
    flex-direction: column;
    align-items: center;
    padding: 25px;
  `};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  color: ${palette.text.secondary};
  padding: 10px;

  ${media.phone`
    padding: 5px;
  `};
`;

const Copyright = styled.span`
  padding: 10px;
  font-size: 12px;
  color: ${palette.text.secondary};
  ${media.phone`
    padding: 5px;
  `};
`;

const Social = styled.ul`
  list-style: none;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
  ${media.phone`
    margin-left: auto;
    margin-right: auto;
  `};
`;

const SocialLi = styled.li`
  padding: 10px;
  display: inline-block;
`;
const SocialLink = styled.a`
  color: ${palette.text.secondary};
`;
const Legal = styled.div`
  display: flex;
  flex-direction: row;

  ${media.phone`
    flex-direction: column;
    align-items: center;
  `};
`;

const LegalLinks = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const Footer = () => (
  <StyledFooter>
    <Social>
      <SocialLi>
        <SocialLink
          href="https://www.linkedin.com/company/keenoa/"
          target="_blank"
          rel="noopener"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </SocialLink>
      </SocialLi>
      <SocialLi>
        <SocialLink
          href="https://www.facebook.com/keenoanutrition/"
          target="_blank"
          rel="noopener"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </SocialLink>
      </SocialLi>
    </Social>
    <Legal>
      <LegalLinks>
        <StyledLink to={'/policy/terms'}>
          <FormattedMessage {...messages.terms} />
        </StyledLink>
        <StyledLink to={'/policy/privacy'}>
          <FormattedMessage {...messages.privacy} />
        </StyledLink>
      </LegalLinks>
      <Copyright>©2016-2024 Keenoa</Copyright>
    </Legal>
  </StyledFooter>
);

export default Footer;
