import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import styled from 'styled-components'

const messages = defineMessages({
  read: {
    id: 'category.read',
  },
})

const CategoryLine = styled.div`
  text-transform: upperCase;
  font-size: 0.7em;
  font-weight: 600;
`

class Category extends React.Component {
  render() {
    const { category, readTime, style } = this.props
    return (
      <CategoryLine style={style}>
        {`${category} | ${readTime} `}
        <FormattedMessage {...messages.read} />
      </CategoryLine>
    )
  }
}

export default Category
