import React from 'react';
import PolicyLinks from '../components/PolicyLinks';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { media, palette } from '../utils/style';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { withHome } from '../layouts/home';

const messages = defineMessages({
  lastUpdate: { id: 'policy.lastUpdate' },
});

const Wrapper = styled.main`
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5em;

  ${media.tablet`
   max-width: 78%;
  `};
  ${media.phone`
   max-width: 90%;
  `};
`;
const Header = styled.h3`
  font-size: 2.6em;
  text-align: center;
  font-weight: 600;
  color: ${palette.text.primary};
`;
const Content = styled.div`
  & > p {
    line-height: 155%;
    color: ${palette.text.secondary};
    margin-bottom: 1em;
  }
  & > h2 {
    font-weight: 600;
    font-size: 1.7em;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  & > h3 {
    font-size: 1.3em;
  }
  & li {
    line-height: 155%;
    margin-bottom: 0.25em;
    color: ${palette.text.secondary};
  }
  & a {
    color: ${palette.primarySaturated};
    text-decoration: none;
  }
`;
const LastUpdate = styled.p`
  font-weight: 600;
  color: ${palette.text.primary};
`;

class Policy extends React.PureComponent {
  render() {
    const data = get(this, 'props.data.contentfulPolicyPage');
    const title = get(data, 'title');
    const lastUpdate = get(data, 'lastUpdate');
    const meta = get(data, 'meta');
    const contentHtml = get(data, 'content.childMarkdownRemark.html');
    const locale = get(this, 'props.pageContext.locale');
    return (
      <Wrapper>
        <PolicyLinks locale={locale} />
        <Header>{title}</Header>
        <LastUpdate>
          <FormattedMessage {...messages.lastUpdate} />
          {lastUpdate}
        </LastUpdate>
        <Content dangerouslySetInnerHTML={{ __html: contentHtml }} />
      </Wrapper>
    );
  }
}
export default withHome(Policy);

export const pageQuery = graphql`
  query PolicyPageQuery($id: String!, $locale: String!) {
    contentfulPolicyPage(id: { eq: $id }) {
      title
      lastUpdate(formatString: "LL", locale: $locale)
      meta {
        ...MetaFragment
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
