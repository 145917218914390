import React from 'react';
import { navigate, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import WhiteFrame from './whiteframe';
import get from 'lodash/get';
import { palette, media } from '../utils/style';
import { buildTo } from '../utils/i18n';
import Author from './Author';
import Category from './Category';

const Wrapper = styled.div`
  flex-basis: 33%;
  max-width: 33%;
  width: 33%;
  padding: 0;
  flex: 0 0 auto;
  display: ${x => (x.first ? 'none' : 'flex')};

  ${media.desktop`
    flex-basis: 50%;
    max-width: 50%;
    width: 50%;
`};
  ${media.phone`
   flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    display: flex;
  `};
`;

const PreviewWrapper = styled(WhiteFrame)`
  width: 100%;
  margin: 10px;
  flex-direction: column;
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;
  align-items: space-between;
`;
const Title = styled.h3`
  text-decoration: none;
  margin: 10px 0 0 0;
  font-weight: 600;
  line-height: 1.2em;
  font-size: 1.3em;
  margin-bottom: 0.8em;

  ${media.phone`
   margin-bottom: 0;
  `};
`;
const Description = styled.p`
  margin: 0;
  color: ${palette.text.secondary};
  font-weight: 300;
  font-family: IBM Plex Serif, serif;

  ${media.phone`
    display: none;
  `};
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
  height: 175px;
`;
const CategoryLine = styled.div`
  text-transform: upperCase;
  font-size: 0.7em;
  font-weight: 600;
`;
class Preview extends React.Component {
  _openPost = () => {
    const { article } = this.props;
    const to = buildTo(`/blog/${article.slug}`, article.locale);
    navigate(to);
  };
  render() {
    const { article, first } = this.props;
    return (
      <Wrapper first={first}>
        <PreviewWrapper level={3} onClick={this._openPost}>
          <ImageWrapper>
            <Img fluid={article.heroImage.fluid} alt="" />
          </ImageWrapper>
          <TextWrapper>
            <Category
              category={get(article, 'categoryName', '')}
              readTime={article.body.childMarkdownRemark.timeToRead}
            />

            <Title>{article.title}</Title>
            <Description>
              {article.body.childMarkdownRemark.excerpt}
            </Description>
            <Author {...article.author} locale={article.locale} />
          </TextWrapper>
        </PreviewWrapper>
      </Wrapper>
    );
  }
}

export default Preview;

export const articlePreviewFragment = graphql`
  fragment ArticlePreviewFragment on ContentfulBlogPostPage {
    id
    title
    heroImage {
      fluid(maxWidth: 1400) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    locale
    slug
    publishDate(formatString: "MMMM Do, YYYY")
    body {
      childMarkdownRemark {
        excerpt(pruneLength: 115)
        timeToRead
      }
    }
    categoryName
    author {
      ...AuthorPreviewFragment
    }
  }
`;
