import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/style';

const UseCasesHeader = styled.h3`
  margin: 50px auto;
  text-align: center;
  max-width: 80%;

  ${media.tablet`
     max-width: 100%;
  `}
  ${media.bigPhone`
     font-size: 1.6em;
   `};
`;

export default UseCasesHeader;
