import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { palette, media } from '../utils/style';
import get from 'lodash/get';

const Wrapper = styled.div`
  position: relative;

  display: flex;
  border-radius: 8px;

  background-color: ${palette.accentBackground};
  overflow: hidden;

  ${media.phone`
    padding: 10px;
  `};
`;

const ImgWrapper = styled.div`
  /* width: 350px; */
  flex: 25%;
  flex-shrink: 0;

  padding-top: 2 0px;

  ${media.bigPhone`
    flex: 75%;
  `};

  ${media.phone`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 250px;
  `};

  > .gatsby-image-wrapper {
    height: 100%;
  }
`;

const ContentWrapper = styled.div`
  flex: 0;

  font-size: 28px;

  ${media.tablet`
    font-size: 24px;
  `};

  ${media.bigPhone`
    font-size: 20px;
  `};
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: 36px;

  ${media.phone`
    margin-top: 24px;
    font-size: 14px;
  `};
`;

const QuoteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 36px 36px 18px 36px;

  ${media.phone`
    margin-left: 0;
    margin-right: 100px;
    padding: 18px 18px 9px 18px;
  `};
`;

function Testimonial({ name, content, photo }) {
  return (
    <Wrapper>
      <ImgWrapper>
        <Img
          fluid={photo.fluid}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'right bottom',
          }}
        />
      </ImgWrapper>
      <QuoteBox>
        <ContentWrapper
          dangerouslySetInnerHTML={{
            __html: get(content, 'childMarkdownRemark.html'),
          }}
        />
        <Name>{name}</Name>
      </QuoteBox>
    </Wrapper>
  );
}
export default Testimonial;

export const TestimonialFragment = graphql`
  fragment TestimonialFragment on ContentfulTestimonial {
    content {
      childMarkdownRemark {
        html
      }
    }
    name
    photo {
      title
      fluid(maxHeight: 450) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
