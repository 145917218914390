/*eslint-disable max-len,quotes*/
export default {
  'category.read': 'MIN READ',
  'article.related': 'Related articles',
  'subscribe.button': 'Subscribe',
  'subscribe.placeholder': 'Enter email to receive our newsletter.',
  'subscribe.thanks': 'Thank you ! You will soon receive your first email.',

  learnMore: 'Learn how',

  'footer.terms': 'Terms of use',
  'footer.privacy': 'Privacy policy',

  'features.step.assesment': 'Nutritional assessment',
  'features.step.teaching': 'Teaching',
  'features.step.between': 'Between appointments',
  'features.step.followUps': 'Nutritional follow up',
  'features.title': 'Keenoa features used',
};
