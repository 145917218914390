import React from 'react';
import styled from 'styled-components';
import Link from './MyLink';
import { palette } from '../utils/style';
import { buildTo } from '../utils/i18n';
import get from 'lodash/get';

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  max-width: 1180px;
`;
const Title = styled.h4`
  font-size: 1.2em;
  font-weight: 600;
  color: ${palette.text.secondary};
  margin-bottom: 1em;
`;
const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  flex: 1;
  min-width: 150px;
`;
const PageLink = styled(Link)`
  padding: 0.5em 0;
  font-size: 0.9em;
  font-weight: 300;
  text-decoration: none;
`;

class Category extends React.PureComponent {
  renderLink = ({ title, internalLink, external }) => {
    const locale = get(this.props, 'locale');
    const to = external ? external : buildTo(internalLink.path, locale);
    return (
      <PageLink key={title} to={to}>
        {title}
      </PageLink>
    );
  };

  render() {
    const { title, links } = this.props;
    return (
      <CategoryWrapper>
        <Title>{title}</Title>
        {links.map(this.renderLink)}
      </CategoryWrapper>
    );
  }
}

class SiteMap extends React.PureComponent {
  render() {
    const categories = get(this.props, 'data.siteMap.links', []);
    const locale = get(this.props, 'locale');
    return (
      <FooterWrapper>
        {categories.map(c => (
          <Category locale={locale} key={c.contentful_id} {...c} />
        ))}
      </FooterWrapper>
    );
  }
}

export default SiteMap;
