import React from 'react';
import { Link } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';
import { palette, media } from '../utils/style';
import EmailForm from './email-form';
import MobileNav from './MobileNav';

import KeenoaSocial from './KeenoaSocial';
import Logo from './Logo';

const TOP_BAR_HEIGHT = 62;

const BaseTopBarWrapper = styled.nav`
  position: fixed;
  right: 0;
  left: 0;
  top: -1px;
  display: flex;
  justify-content: center;
  z-index: 1;
  height: ${TOP_BAR_HEIGHT}px;
  transition: background-color 600ms 0s ease;

  background-color: white;
  opacity: 0.97;
  z-index: 2;

  ${media.tablet`
    display: none;
  `};
`;
const BaseContentWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 45px;
  padding-right: 45px;
  max-width: 1180px;
  width: 100%;
  overflow-x: hidden;

  display: flex;
  align-items: center;
`;

const CategoryLink = styled(Link)`
  margin: 13px;
  font-size: 0.9em;
  text-decoration: none;
  font-weight: 500;
  color: ${palette.text.secondary};

  &:hover {
    color: ${palette.primarySaturated};
  }
`;

class Navigation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      light: false,
    };
  }

  renderMobileCategories = toggleMenu => {
    const categories = get(this, 'props.data.allContentfulCategory.edges', []);
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* {categories.map(({ node }) => (
          <Link
            to={buildTo(`/blog/${node.slug}`, this.props.locale)}
            key={node.id}
            onClick={toggleMenu}
            style={{
              textDecoration: 'none',
              padding: '10px 20px',
              borderBottom: `1px solid ${palette.lightGray}`,
            }}
          >
            <CategoryName name={node.name} style={{ fontSize: '1.3em' }} />
            <div style={{ color: palette.text.secondary, fontSize: '0.9em' }}>
              {node.description.description}{' '}
            </div>
          </Link>
        ))} */}
        <div style={{ padding: '20px' }}>
          <EmailForm large />
        </div>
        <KeenoaSocial
          style={{
            textAlign: 'center',
            margin: 0,
            paddingBottom: '20px',
          }}
        />
      </div>
    );
  };

  renderLogo = () => (
    <Logo
      fixed={get(this, 'props.data.contentfulAsset.fixed')}
      locale={get(this, 'props.locale')}
    />
  );

  renderDesktop = () => {
    return (
      <BaseTopBarWrapper role="navigation">
        <BaseContentWrapper>
          {this.renderLogo()}
          {/* {this.props.scrolled
            ? null
            : categories.map(({ node }) => (
                <CategoryLink
                  key={node.id}
                  to={buildTo(`/blog/${node.slug}`, this.props.locale)}
                  activeStyle={{ color: palette.primarySaturated }}
                  exact
                >
                  {node.name}
                </CategoryLink>
              ))} */}
          {/* <LocalePicker
            location={this.props.location}
            locale={this.props.locale}
          /> */}
          {/* <div
            style={{ flex: 1, display: 'flex', flexDirection: 'row-reverse' }}
          >
            <EmailForm />
          </div> */}
        </BaseContentWrapper>
      </BaseTopBarWrapper>
    );
  };

  render() {
    // const categories = get(this, 'props.data.allContentfulCategory.edges', []);
    return (
      <div>
        <MobileNav
          blog
          renderLogo={this.renderLogo}
          renderMenu={this.renderMobileCategories}
        />
        {this.renderDesktop()}
      </div>
    );
  }
}
export default Navigation;
