import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import faTwitter from '@fortawesome/free-brands-svg-icons/faTwitter';
import { palette } from '../utils/style';

const Social = styled.ul`
  list-style: none;
  display: inline-block;
  padding: 0;
`;

const SocialLi = styled.li`
  padding: 10px;
  display: inline-block;
`;
const SocialLink = styled.a`
  color: ${palette.text.secondary};
`;
export default ({ className, style }) => (
  <Social className={className} style={style}>
    <SocialLi>
      <SocialLink
        href="https://www.linkedin.com/company/keenoa/"
        target="_blank"
        rel="noopener"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </SocialLink>
    </SocialLi>
    <SocialLi>
      <SocialLink
        href="https://www.facebook.com/keenoanutrition/"
        target="_blank"
        rel="noopener"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </SocialLink>
    </SocialLi>
    <SocialLi>
      <SocialLink
        href="https://twitter.com/keenoanutrition"
        target="_blank"
        rel="noopener"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </SocialLink>
    </SocialLi>
  </Social>
);
