/*eslint-disable max-len,quotes*/
export default {
  'covid.banner':
    "Réponse de COVID-19: Extension de l'essai gratuit, suppression des limites",
  'covid.learnMore': "Plus d'info",

  'tabBar.getStarted': 'Essayer Gratuitement',

  'signup.name': 'Nom complet',
  'signup.password': 'Mot de passe',
  'signup.passwordExplanation':
    'Assurez-vous que votre mot de passe contient au moins 6 lettres.',
  'signup.email': 'Adresse courriel',
  'signup.submitBtn': "S'inscrire",
  'signup.smallTerms': 'En vous inscrivant, vous acceptez les ',
  'signup.terms': "Conditions d'utilisation",
  'signup.smallTermsMiddle': ' et les ',
  'signup.subscriptionTerms': "Conditions d'inscription et d'abonnement",
  'signup.invalidError': 'Le courriel entré est invalide.',
  'signup.usedError': 'Le courriel entré est déjà utilisé.',
  'signup.boardName': 'Nom de votre ordre professionnel',
  'signup.permitNumber': 'Numéro de licence',
  'signup.permitNumberExplanation':
    'Nous avons besoin de cette information pour valider votre statut professionel.',
  'signup.aboutYou': 'Nutritionniste',
  'signup.credentials': 'Identifiants de connexion',
  'signup.logIn': 'Connexion',
  'signup.existingAccount': 'Vous avez déja un compte ?',
  'signup.creating': 'Création du compte...',

  'policy.terms.link': 'Conditions',
  'policy.privacy.link': 'Confidentialité',
  'policy.subscription.link': 'Abonnement',

  'questions.goal.health': 'Santé et mise en forme',
  'questions.goal.weightLoss': 'Saine gestion du poids',
  'questions.goal.diabete': 'Diabète',
  'questions.goal.hypertension': 'Santé cardiovasculaire',
  'questions.goal.allergy': 'Allergies',
  'questions.goal.pregnancy': 'Grossesse',
  'questions.goal.vegan': 'Végétarien / végétalien',
  'questions.goal.performance': 'Performance',
  'questions.goal.other': 'Autre',
  'questions.goal.header': 'Mon <b>objectif nutrionnel</b> est:',
  'questions.goal.subheader':
    'On va vous mettre en contact avec un/une nutritionniste spécialisée selon vos besoins/objectifs.',

  'questions.motivation.not': 'Aucunement motivé',
  'questions.motivation.little': 'Peu motivé',
  'questions.motivation.motivated': 'Motivé',
  'questions.motivation.very': 'Très motivé',
  'questions.motivation.extremely': 'Extrêmement motivé',
  'questions.motivation.header':
    'Je me sens<b> _____________ </b>à poursuivre l’atteinte de mon objectif nutritionnel.',
  'questions.motivation.subheader':
    'Votre nutritionniste va ajuster son approche selon votre niveau de motivation.',

  'questions.history.weightLoss': 'Programme de gestion de poids',
  'questions.history.dietitian': 'Un.e nutritionniste',
  'questions.history.alternative': 'Naturopathe, entraineur',
  'questions.history.apps': 'App fitness/nutrition',
  'questions.history.other': 'Autres',
  'questions.history.header':
    "J'ai déjà suivi/utilisé/reçu des <b>conseils nutritionnels</b> de:",
  'questions.history.nextStep': 'Suite',
  'questions.history.none': 'Aucun',
  'questions.history.subheader':
    "Avoir une idée de votre historique va accélèrer le processus d'évaluation.",

  'questions.address.header':
    'Je veux que mes consultations soient <b>près de </b>:',
  'questions.address.nextStep': 'Dernière étape',
  'questions.address.placeholder': 'Ville, code postal ou établissement...',
  'questions.address.subheader':
    "On s'assure que ce soit facile pour vous de rencontrer votre nutritionniste.",

  'questions.email.header': 'Mon adresse <b>courriel</b> est: ',
  'questions.email.placeholder': 'Courriel*',
  'questions.email.find': 'Suivant',
  'questions.email.terms':
    "*En nous fournissant votre adresse courriel, vous acceptez qu'un nutrionniste vous contacte pour discuter de vos besoins.",

  'questions.confirmation.header': 'Génial, un courriel vous a été envoyé.',
  'questions.confirmation.subheader':
    'Merci d’avoir envoyé votre requête, notre équipe va personnellement vous présenter au meilleur nutritionniste pour vous.',
  'questions.confirmation.backHome': "Retour à la page d'acceuil",

  'questions.request.header':
    'Nous trouverons la meilleure nutritionniste pour vous',
  'questions.request.subheader':
    'Notre équipe va personnellement vous présenter à un/une nutritionniste qui saura répondre à vos besoins. S’il y a d’autres informations qu’on devrait savoir pour pouvoir mieux vous aider, veuillez l’écrire ici.',
  'questions.request.find': 'Trouvez-moi un nutritionniste',
  'questions.request.placeholder':
    "Ex. C'est pour une consultation de couple, j'aimerais si...",

  'pricing.faq': 'Foire aux questions',

  'footer.useCases.title': 'Utilisations',
  'footer.useCases.motivate': 'Motiver',
  'footer.useCases.habits': 'Découvrir les habitudes alimentaires',
  'footer.useCases.grow': 'Élargir votre clientèle',

  'footer.company.title': 'Entreprise',
  'footer.company.aboutUs': 'À propos',
  'footer.company.contactUs': 'Nous rejoindre',

  'footer.client.title': 'Client',
  'footer.client.why': 'Pourquoi Keenoa ?',
  'footer.client.find': 'Trouver un nutritionniste',
  'footer.client.gettingStarted': "Utiliser l'app Keenoa",

  'footer.ressources.title': 'Ressources',
  'footer.ressources.blog': 'Blogue',
  'footer.ressources.help': 'Aide',

  'about.mission': 'Notre mission',
  'about.team': 'Notre équipe',
  'about.supporters': 'Supporters',

  'policy.lastUpdate': 'Dernière mise à jour: ',

  'footer.terms': "Conditions d'utilisation",
  'footer.privacy': 'Polique relative à la vie privée',

  'home.learn': 'En savoir plus',
  'home.supporters': 'Ils nous supportent',
  languages: 'Langues',
};
