import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Benefit from '../components/Benefit';
import Meta from '../components/Meta';
import Header from '../components/Header';
import UseCaseCard from '../components/UseCaseCard';
import { withHome } from '../layouts/home';
import Testimonial from '../components/Testimonial';
import CustomersBanner from '../components/CustomersBanner';
import CustomersBannerTwo from '../components/CustomersBannerTwo';
import FinalArguments from '../components/FinalArguments';
import Spacer from '../components/Spacer';
import Alternatives from '../components/Alternatives';
import UseCasesHeader from '../components/UseCaseHeader';

const FINAL_ARGUMENTS = {
  en: {
    arguments: [
      {
        text: {
          id: 'd50f8020-49b9-5b03-bb66-bb0f050d6dd9',
          childMarkdownRemark: {
            html:
              '<p>Be confident with the use of Keenoa <strong>within less than 5 minutes</strong></p>',
          },
        },
      },
      {
        text: {
          id: 'b449d633-b873-5c8b-ab38-1dd15a1b0fbf',
          childMarkdownRemark: {
            html: '<p>Deidentified secure research flow </p>',
          },
        },
      },
      {
        text: {
          id: '7382b906-7589-5151-b267-323ff0d09152',
          childMarkdownRemark: {
            html: '<p>Enroll <strong>unlimited participants</strong> </p>',
          },
        },
      },
      {
        text: {
          id: 'c92422ba-d6a1-50b7-a407-0b50f89b9583',
          childMarkdownRemark: {
            html:
              '<p><strong>Flexible pricing</strong> to suit your research needs</p>',
          },
        },
      },
    ],
    callToAction: {
      title: 'Create your Keenoa account now',
      node_locale: 'en',
      detail: null,
      to: {
        title: 'Sign up',
        internalLink: {
          path: '/signup',
        },
        external: null,
        emailLink: null,
      },
    },
  },
  fr: {
    arguments: [
      {
        text: {
          id: '68d1e76c-333e-5c18-913f-11d5d41ee3c8',
          childMarkdownRemark: {
            html:
              '<p>Soyez <strong>confiant</strong> dans votre utilisation de la plateforme en <strong>moins de 5 minutes</strong></p>',
          },
        },
      },
      {
        text: {
          id: 'fd952c3c-78a3-51c4-865e-3058820fbae5',
          childMarkdownRemark: {
            html: '<p>Flux de recherche sécurisé et dépersonnalisé </p>',
          },
        },
      },
      {
        text: {
          id: 'e75db5d7-9bb0-5d43-a33b-5aff50e8e80f',
          childMarkdownRemark: {
            html:
              '<p>Inscription de <strong>participants illimités</strong> </p>',
          },
        },
      },
      {
        text: {
          id: '1b08af65-dd3b-5aad-8d0a-d0dd87cc3b68',
          childMarkdownRemark: {
            html:
              '<p><strong>Prix flexibles</strong> pour répondre à vos besoins de recherche</p>',
          },
        },
      },
    ],
    callToAction: {
      title: 'Créez votre compte Keenoa maintenant',
      node_locale: 'fr',
      detail: null,
      to: {
        title: "S'inscrire",
        internalLink: {
          path: '/signup',
        },
        external: null,
        emailLink: null,
      },
    },
  },
};

class Home extends React.Component {
  render() {
    const data = get(this, 'props.data.contentfulLandingPage');

    const meta = get(data, 'meta');
    const header = get(data, 'header');

    const alternativesHeader = get(data, 'alternativesHeader.text');
    const alternatives = get(data, 'alternatives');

    const mainTestimonial = get(data, 'mainTestimonial');

    const benefitsHeader = get(data, 'benefitsHeader.text');
    const benefits = get(data, 'benefits') || [];

    const researchCustomers = get(data, 'researchCustomers');

    const useCasesHeader = get(data, 'useCasesHeader');
    const useCases = get(data, 'useCases');

    const dietitianCustomers = get(data, 'dietitianCustomers');

    const finalArguments = FINAL_ARGUMENTS[get(data, 'node_locale')];

    return (
      <div>
        <Meta {...meta} />
        <Header {...header} imageFocus />
        <Spacer />
        <UseCasesHeader
          style={{ marginBottom: 10 }}
          dangerouslySetInnerHTML={{
            __html: get(alternativesHeader, 'childMarkdownRemark.html'),
          }}
        />
        <Alternatives alternatives={alternatives} />
        <Spacer lg />
        <Testimonial {...mainTestimonial} />
        <Spacer />
        <UseCasesHeader
          dangerouslySetInnerHTML={{
            __html: get(benefitsHeader, 'childMarkdownRemark.html'),
          }}
        />
        {benefits.map((benefit, i) => (
          <>
            <Benefit
              key={i}
              {...benefit}
              accentuate
              image_side={i % 2 == 0 ? 'left' : 'right'}
              expand={i === 1}
            />
            <Spacer lg />
          </>
        ))}
        <Spacer lg />
        <CustomersBanner {...researchCustomers} />
        <Spacer lg />
        <UseCasesHeader>{useCasesHeader}</UseCasesHeader>
        <div
          style={{ display: 'flex', flexFlow: 'row wrap', marginTop: '-40px' }}
        >
          {useCases.map((useCase, i) => (
            <UseCaseCard key={i} i={i} {...useCase} />
          ))}
        </div>
        <Spacer lg />
        <CustomersBannerTwo {...dietitianCustomers} />
        <FinalArguments {...finalArguments} />
      </div>
    );
  }
}

export default withHome(Home);

export const pageQuery = graphql`
  query LandingPageQuery($id: String!) {
    contentfulLandingPage(id: { eq: $id }) {
      node_locale
      header {
        ...HeaderFragment
      }
      meta {
        ...MetaFragment
      }
      alternativesHeader {
        ...TextFragment
      }
      alternatives {
        ...AlternativeFragment
      }
      mainTestimonial {
        ...TestimonialFragment
      }
      benefitsHeader {
        ...TextFragment
      }
      benefits {
        ...BenefitFragment
      }
      researchCustomers {
        ...CustomersBannerFragment
      }
      useCasesHeader
      useCases {
        ...UseCaseCardFragment
      }
      dietitianCustomers {
        ...CustomersBannerFragment
      }
      supporters {
        fixed(width: 325) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
    }
  }
`;
