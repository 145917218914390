// We need to mock otherwise it fails when building in production
export function windowSafe() {
  if (typeof window !== `undefined`) {
    return window;
  }
  // Should only be the case during build
  return {
    location: {
      search: '',
      pathname: '',
      href: '',
    },
    localStorage: {
      setItem: () => {},
      getItem: () => {},
      removeItem: () => {},
      clear: () => {},
    },
    matchMedia: () => true,
  };
}

export function getUrlParameter(param) {
  var url = new URL(windowSafe().location.href);
  return url.searchParams.get(param);
}
