export default [
  {
    id: 1,
    label: 'College of Dietitians of British Columbia',
  },
  {
    id: 2,
    label: 'College of Dietitians of Alberta',
  },
  {
    id: 3,
    label: 'Saskatchewan Dietitians Association',
  },
  {
    id: 4,
    label: 'College of Dietitians of Manitoba',
  },
  {
    id: 5,
    label: 'College of Dietitians of Ontario',
  },
  {
    id: 6,
    label: 'Ordre professionnel des diététistes du Québec',
  },
  {
    id: 7,
    label: 'New Brunswick Association of Dietitians',
  },
  {
    id: 8,
    label: 'Nova Scotia Dietetic Association',
  },
  {
    id: 9,
    label: 'PEI Dietitians Registration Board',
  },
  {
    id: 10,
    label: 'Newfoundland and Labrador College of Dietitians',
  },
  {
    id: 0,
    label: 'Other',
  },
];
