import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { palette, media } from '../utils/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/pro-regular-svg-icons';
import { faMinusSquare } from '@fortawesome/pro-regular-svg-icons';
import { graphql } from 'gatsby';

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  max-width: 750px;
  border-bottom: 1px solid ${palette.lightGray2};
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.01);
  }
  ${media.bigPhone`
    font-size: 12px;
  `};
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Question = styled.h4`
  margin: 0;
  color: ${palette.text.primary};
  font-weight: 500;
  font-size: 1.4em;
  padding-right: 60px;
`;
const Answer = styled.div`
  color: ${palette.text.secondary};
  font-size: 1.2em;
`;

class QnA extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  _toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const question = get(this, 'props.question');
    const answerHtml = get(this, 'props.answer.childMarkdownRemark.html');

    return (
      <Wrapper onClick={this._toggle}>
        <Question>{question}</Question>
        <IconWrapper>
          <FontAwesomeIcon
            color={palette.primarySaturated}
            style={{ fontSize: '1.6em' }}
            icon={this.state.open ? faMinusSquare : faPlusSquare}
          />
        </IconWrapper>
        {!this.state.open ? null : (
          <Answer dangerouslySetInnerHTML={{ __html: answerHtml }} />
        )}
      </Wrapper>
    );
  }
}
export default QnA;
