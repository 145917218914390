import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media, palette, useMedia } from '../utils/style';
import Img from 'gatsby-image';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import Review from './Review';
import Argument from './Argument';
import Testimonal from './SideTestimonial';

const Wrapper = styled.section`
  margin-left: auto;
  margin-right: auto;

  padding: 20px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${media.tablet`
    flex-wrap: wrap;
  `};
  ${media.phone`
    flex-direction: ${props =>
      props.side === 'left' ? 'column' : 'column-reverse'};
    
  `};
`;

const HorizontalWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  margin-bottom: 35px;
`;

const ContentWrapper = styled.div`
  ${x => (x.expand ? 'margin-right: -150px' : '')};
  width: 60%;

  flex: ${x => (x.expand ? 1.7 : 1)};
  display: flex;

  ${media.tablet`
    flex-basis: 100%;
    max-width: 100%;
    max-height: 350px;
    width: 100%;
    padding: 5px;
  `};
`;

const BenefitImg = styled(Img)`
  width: 100%;
  height: 100%;
  z-index: -1;
  max-height: 450px;

  ${media.tablet`
    max-height: 350px;
  `};

  ${props =>
    props.side === 'left' ? 'margin-right: auto' : 'margin-left: auto'};
`;

const ArgumentsWrapper = styled.div`
  padding: 10px;
`;

const ArgumentTitle = styled.h4`
  font-size: 2.3em;
  line-height: 1.3em;
  font-weight: 700;
  color: ${palette.text.primary};
  max-width: 750px;
  margin-top: 0;

  ${media.tablet`
    font-size: 1.7em;
    margin-top: 20px;
    margin-bottom: 0;

  `};

  ${media.phone`
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  `};
`;

const GridArgument = styled.div`
  margin: 0 10px;
  flex-grow: 1;
  width: 230px;
  max-width: 450px;
`;

const TestimonialWrapper = styled.div`
  padding: 1.5em 2.6em;
  background-color: ${palette.accentBackground};
  border-radius: 8px;
  margin-top: 2em;
  position: relative;
  color: ${palette.text.primary};
`;

const Quote = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
`;

const ArgumentsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 104px 0;

  ${media.tablet`
    padding:  0;
    padding-top: 10px;
  `}
`;

export default function Benefit(props) {
  const renderImage = () => {
    const { image, image_side, expand } = props;
    if (!image) return null;
    return (
      <ContentWrapper image expand={expand}>
        <BenefitImg
          side={image_side}
          fluid={image.fluid}
          expand={props.expand}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
      </ContentWrapper>
    );
  };

  const renderArguments = () => {
    const textSide = props.image_side === 'left' ? 'right' : 'left';
    const args = props.arguments || [];
    return (
      <ContentWrapper>
        <ArgumentsWrapper>
          <ArgumentTitle side={textSide}>{props.title}</ArgumentTitle>
          {args.map((argum, i) => (
            <React.Fragment key={i}>
              <Argument {...argum} accentuate={props.accentuate} />
            </React.Fragment>
          ))}
          {renderTestimonial()}
        </ArgumentsWrapper>
      </ContentWrapper>
    );
  };

  const renderTestimonial = () => {
    const testimonial = props.testimonial;
    if (!testimonial) return null;

    const { content, name, photo } = testimonial;
    if (props.expand)
      return (
        <div>
          <Testimonal {...testimonial} />
        </div>
      );

    return (
      <TestimonialWrapper>
        <div>
          <Quote icon={faQuoteLeft} style={{ top: 10, left: 10 }} />
          <div style={{ position: 'relative' }}>
            <Quote icon={faQuoteRight} style={{ bottom: -10, right: -10 }} />
            <div
              dangerouslySetInnerHTML={{
                __html: get(content, 'childMarkdownRemark.html'),
              }}
            />
          </div>
          <div style={{ marginTop: 10, display: 'flex' }}>
            <Img fixed={photo.fixed} style={{ borderRadius: 8 }} />
            <b
              style={{ fontWeight: 600, marginLeft: '1em', marginTop: '0.5em' }}
            >
              {name}
            </b>
          </div>
        </div>
      </TestimonialWrapper>
    );
  };

  const renderVertical = () => {
    const { image_side } = props;

    const content =
      image_side === 'left' ? (
        <Wrapper side={image_side}>
          {renderImage()}
          {renderArguments()}
        </Wrapper>
      ) : (
        <Wrapper side={image_side}>
          {renderArguments()}
          {renderImage()}
        </Wrapper>
      );

    return <div style={{ overflow: 'visible' }}>{content}</div>;
  };

  const renderHorizontal = () => {
    const args = props.arguments || [];
    const { title, image } = props;
    return (
      <HorizontalWrapper>
        <ArgumentTitle side={'left'}>{title}</ArgumentTitle>
        <Img
          fluid={image.fluid}
          outerWrapperClassName="benefit-image"
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'center',
          }}
          style={{ maxHeight: 450 }}
        />
        <ArgumentsWrap>
          {args.map(argument => (
            <GridArgument key={argument.id}>
              <Argument {...argument} accentuate={props.accentuate} />
            </GridArgument>
          ))}
        </ArgumentsWrap>
        {renderTestimonial()}
      </HorizontalWrapper>
    );
  };

  const renderReviews = () => {
    const reviews = props.reviews || [];
    return (
      <div>
        {reviews.map((r, i) => (
          <Review {...r} i={i} />
        ))}
      </div>
    );
  };

  const { horizontal } = props;
  const media = useMedia();
  return (
    <>
      {horizontal || media.tablet ? renderHorizontal() : renderVertical()}
      {renderReviews()}
    </>
  );
}

export const benefitFragment = graphql`
  fragment BenefitFragment on ContentfulBenefit {
    title
    arguments {
      ...ArgumentFragment
    }
    horizontal
    image {
      fluid(maxWidth: 1050) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    testimonial {
      content {
        childMarkdownRemark {
          html
        }
      }
      name
      photo {
        title
        fixed(height: 80, width: 80, resizingBehavior: THUMB) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
        fluid(maxHeight: 400) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    reviews {
      ...ReviewFragment
    }
  }
`;
