import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { graphql } from 'gatsby';

// This component renders the "standard page meta". Those meta
// are the ones that are specified on every and in the content-
// ful meta Content Model
class Meta extends React.PureComponent {
  render() {
    const title = get(this, 'props.title');
    const description = get(this, 'props.description.description');
    const image = get(this, 'props.image.fixed.src', undefined);

    return (
      <Helmet title={title}>
        <meta name="description" content={description} />
        {/* OpenGraph tags */}
        <meta property="og:title" content={title} />
        {image ? <meta property="og:image" content={image} /> : null}
        {image ? <meta property="og:image:secure_url" content={image} /> : null}
        <meta property="og:description" content={description} />

        {/* Twitter Card tags */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {image ? <meta name="twitter:image" content={image} /> : null}
      </Helmet>
    );
  }
}
export default Meta;
Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  image: PropTypes.object,
};

export const metaFragment = graphql`
  fragment MetaFragment on ContentfulMeta {
    title
    description {
      description
    }
    image {
      fixed(width: 1200) {
        src
      }
    }
  }
`;
