import React, { Component } from 'react';
import styled from 'styled-components';

const shadowLevels = {
  1: '0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)',
  2: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
  3: '0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)',
  10: '0 6px 6px -3px rgba(0,0,0,.2), 0 10px 14px 1px rgba(0,0,0,.14), 0 4px 18px 3px rgba(0,0,0,.12)',
};

export const StyledDiv = styled.div`
  box-shadow: ${props => shadowLevels[props.level]};
  background-color: white;
  padding: 0;
  transition: box-shadow 0.15s linear;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    box-shadow: ${props => (props.onClick ? shadowLevels[10] : '')};
  }
`;

class WhiteFrame extends React.PureComponent {
  render() {
    return (
      <StyledDiv
        level={this.props.level | 1}
        style={this.props.style}
        className={this.props.className}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </StyledDiv>
    );
  }
}

export default WhiteFrame;
