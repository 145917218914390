import React from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { media, palette } from '../utils/style';
import Link from './MyLink';
import { buildTo, buildLinkTo } from '../utils/i18n';
import MobileNav from './MobileNav';
import KeenoaSocial from './KeenoaSocial';
import Logo from './Logo';
import get from 'lodash/get';
import 'react-tippy/dist/tippy.css';
import BarLink, { LinkA, LinkDiv } from './BarLink';
import LinksGroup from './LinksGroup';
import flatten from 'lodash/flatten';
import { navigate, graphql } from 'gatsby';
import LocalePicker, { MobilePicker } from './LocalePicker';
import { FEATURE_FLAGS } from '../constants/featureFlags';
import { CONSTANTS } from '../constants';

const messages = defineMessages({
  getStarted: { id: 'tabBar.getStarted' },
});

export const TOP_BAR_HEIGHT = 65;

const SignUpButtonWrapper = styled.div`
  ${media.desktop`
    display: none;
  `};
`;

const BaseTopBarWrapper = styled.header`
  position: fixed;
  right: 0;
  left: 0;
  top: ${FEATURE_FLAGS.BANNER_ENABLED ? CONSTANTS.BANNER_HEIGHT : -1}px;
  display: flex;
  justify-content: center;
  z-index: 1;
  height: ${TOP_BAR_HEIGHT}px;
  transition: background-color 600ms 0s ease;

  background-color: white;
  color: inherit;
  z-index: 1000;

  ${media.tablet`
    display: none;
  `};
`;

const MobileLink = styled(LinkA)`
  margin: 0;
  padding: 0;
`;

const BaseContentWrapper = styled.div`
  width: 100vw;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  justify-content: space-between;
  font-size: 12px;
  transition: margin-top 600ms 0s ease;

  ${media.phone`
    padding: 0 20px;
  `};
`;

// We are not extending the Button component because there
// was a classname ordering issue in prod build
const SignUpButton = styled.button`
  background-color: white;
  border: 2px solid ${palette.primarySaturated};
  font-weight: 600;
  font-size: 14px;
  padding: 8px 12px;
  color: ${palette.primarySaturated};
  transition: background-color 0.15s, color 0.15s ease-in-out;
  border-radius: 10px;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${palette.primarySaturated};
    color: white;
    cursor: pointer;
  }
`;

class TopBar extends React.PureComponent {
  renderLinks = () => {
    const links = get(this, 'props.data.topBar.links') || [];
    const locale = get(this, 'props.locale');
    return links.map((link, i) => {
      if (link.__typename === 'ContentfulLink') {
        return <BarLink key={i} {...link} locale={locale} />;
      }
      return <LinksGroup key={i} {...link} locale={locale} />;
    });
  };

  renderMobileLink = (link, onClick) => {
    const { title, internalLink, external } = link;
    return (
      <MobileLink
        key={title}
        onClick={onClick}
        to={buildLinkTo(
          { external, internal: internalLink },
          this.props.locale
        )}
      >
        <div
          style={{
            padding: '10px',
            textAlign: 'center',
            fontSize: '0.9em',
            borderBottom: `1px solid ${palette.lightGray}`,
          }}
        >
          {title}
        </div>
      </MobileLink>
    );
  };

  renderMobile = () => {
    const links = get(this, 'props.data.topBar.links');
    return (
      <MobileNav
        renderLogo={() => (
          <Logo
            fixed={get(this, 'props.data.contentfulAsset.fixed')}
            locale={get(this, 'props.locale')}
          />
        )}
        renderMenu={toggle => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {flatten(
              links.map((link, i) => {
                if (link.__typename === 'ContentfulLink') {
                  return this.renderMobileLink(link, toggle);
                } else {
                  const { title, links } = link;
                  return (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderBottom: `1px solid ${palette.lightGray}`,
                      }}
                    >
                      <LinkDiv style={{ paddingBottom: 0 }}>{title}</LinkDiv>
                      {links.map((x, i) => (
                        <Link
                          key={i}
                          to={buildTo(
                            get(x, 'internalLink.path'),
                            this.props.locale
                          )}
                          onClick={toggle}
                          style={{
                            textDecoration: 'none',
                            padding: '4px 0',
                            color: palette.text.primary,
                          }}
                        >
                          {x.title}
                        </Link>
                      ))}
                    </div>
                  );
                }
              })
            )}
            <div
              style={{
                paddingTop: '15px',
                textAlign: 'center',
              }}
            >
              {this.renderSignUpButton()}
            </div>
            <MobilePicker
              locale={this.props.locale}
              location={this.props.location}
            />
            <KeenoaSocial
              style={{
                textAlign: 'center',
                margin: 0,
                padding: '20px',
              }}
            />
          </div>
        )}
      />
    );
  };

  renderSignUpButton = () => {
    return (
      <SignUpButtonWrapper>
        <SignUpButton
          onClick={() => {
            navigate(buildTo('/signup', this.props.locale));
          }}
        >
          <FormattedMessage {...messages.getStarted} />
        </SignUpButton>
      </SignUpButtonWrapper>
    );
  };

  renderDesktop = () => {
    const { media, data } = this.props;
    const logoFixed = get(data, 'contentfulAsset.fixed');
    return (
      <BaseTopBarWrapper>
        <BaseContentWrapper>
          <Logo fixed={logoFixed} locale={get(this, 'props.locale')} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {this.renderLinks()}
            {this.renderSignUpButton()}
            <LocalePicker
              location={this.props.location}
              locale={this.props.locale}
            />
          </div>
        </BaseContentWrapper>
      </BaseTopBarWrapper>
    );
  };

  render() {
    return (
      <div>
        {this.renderMobile()}
        {this.renderDesktop()}
      </div>
    );
  }
}

export default TopBar;
export const linkFragment = graphql`
  fragment LinkFragment on ContentfulLink {
    title
    internalLink {
      path
    }
    external
  }
`;
