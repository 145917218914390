import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Meta from '../components/Meta';
import Header from '../components/Header';
import QnA from '../components/QnA';
import { withHome } from '../layouts/home';

const QnAs = {
  en: [
    {
      question:
        'Do I need to enter all the details right after I take the picture?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>No, you don’t have to complete the entry right away. You can choose to complete the entry later.</p>',
        },
      },
    },
    {
      question:
        'I saved my meal but I didn’t eat everything that was in the picture. What should I do?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>You can go back to that item and make changes to the quantities you have initially entered. Alternatively, you can take a picture of what was left on your plate. This option will also give your dietitian a really good picture. Either way could work!</p>',
        },
      },
    },
    {
      question:
        'I often eat the same meal in the morning. Do I need to enter the same foods every time?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>Keenoa will recognize similar foods that have been entered previously. Once you take the picture of the food or meal, you will be able to copy that meal without having to enter the details again.</p>',
        },
      },
    },
    {
      question:
        'What should I do if none of the labels correspond to what is shown in my picture?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>You can manually search for the food by typing into the search bar at the top of the screen. You can start by typing in a single word. If you still can’t find the right food, you can use the Comment feature to leave a note for your dietitian. </p>',
        },
      },
    },
    {
      question: 'Do I need to use Keenoa every day?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>The number of days you should be using Keenoa to record what you eat will depend on the nutrition plan that your dietitian has prepared for you. To get a good picture of your usual diet, it would be good to log at least two work days and one off day.</p>',
        },
      },
    },
    {
      question:
        'I forgot to take a picture of what I ate and I already ate everything. What should I do?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>Don’t panic, this happens! You can add a meal without a picture from the diary tab. Feel free to pick an emoji that best describes your meal! 🙂</p>',
        },
      },
    },
    {
      question:
        'I left a note for my dietitian using the Comment feature, but I didn’t get a response. Is there something wrong with the app?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>The Comment feature allows both the client and the dietitian to leave notes for each other. Normally, your dietitian will be looking at your food entries, including your notes, with you during your appointment. Your dietitian can also use the Comment feature to leave you tips on the app!</p>',
        },
      },
    },
  ],
  fr: [
    {
      question:
        'Dois-je entrer tous les détails immédiatement après avoir pris une photo ?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>Non, vous n’avez pas à compléter les détails immédiatement. Vous pouvez choisir de compléter les détails plus tard. </p>',
        },
      },
    },
    {
      question:
        'J’ai sauvegardé mon repas, mais je n’ai pas tout mangé ce qui apparait dans la photo, que dois-je faire ?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>Vous pouvez retourner sur la photo pour modifier les quantités que vous aviez initialement entrées. Également, vous pouvez prendre une seconde photo de ce qui reste dans votre assiette. Cette option aidera votre nutritionniste à voir clairement ce que vous n’avez pas manger.</p>',
        },
      },
    },
    {
      question:
        'Je mange souvent le même repas le matin. Dois-je entrer les mêmes aliments à chaque fois?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>Keenoa va reconnaitre les repas qui ont été précédemment consommés. Lorsque vous prenez une photo de votre assiette, vous allez pouvoir simplement pouvoir copier votre ancien repas sans avoir à ajouter les détails.</p>',
        },
      },
    },
    {
      question:
        'Que dois-je faire si un aliment n’est pas détecté par l’intelligence artificielle ?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>Vous pouvez manuellement chercher l’aliment avec la barre de recherche au haut de l’écran. Si vous avez de la difficulté à trouver un aliment, vous pouvez utiliser la section commentaire pour laisser une note pour votre nutritionniste.</p>',
        },
      },
    },
    {
      question: 'Dois-je utiliser Keenoa tous les jours?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>Le nombre de jours durant lesquels vous devriez utiliser Keenoa pour suivre votre alimentation va dépendre du plan nutritionnel que votre nutritionniste va préparer pour vous. Pour obtenir une idée générale de votre diète, il est recommandé de suivre au moins deux journées de travail ainsi qu’une de congé.</p>',
        },
      },
    },
    {
      question:
        'J’ai oublié de prendre une photo et j’ai déjà mangé mon repas. Que devrais-je faire ?',
      answer: {
        childMarkdownRemark: {
          html:
            "<p>Aucune inquiétude, ça arrive ! Vous pouvez ajoutez une repas sans photo à partir de l'onglet journal de votre app. Vous pouvez également choisir l'emoji qui décrit le mieux votre repas. 🙂</p>",
        },
      },
    },
    {
      question:
        'J’ai laissé un message à ma nutritionniste avec la fonctionnalité de commentaire, mais je n’ai pas reçu de réponse. Est-ce qu’il y a un problème avec l’app ?',
      answer: {
        childMarkdownRemark: {
          html:
            '<p>La fonctionnalité de commentaire permet aux clients et au nutritionniste de se laisser des notes. Normalement, votre nutritionniste regardera votre journal, incluant vos notes, avec vous lors de votre rendez-vous. Votre nutritionniste peut également utiliser les commentaires pour vous laisser des conseils qui seront disponibles dans l’app!</p>',
        },
      },
    },
  ],
};

class Faq extends React.Component {
  render() {
    const data = get(this, 'props.data.contentfulPage');
    const meta = get(data, 'meta');
    const header = get(data, 'header');
    const faqs = QnAs[get(data, 'node_locale')] || [];

    return (
      <div>
        <Meta {...meta} />
        <Header {...header} />
        {faqs.map((f, i) => (
          <QnA key={i} {...f} />
        ))}
      </div>
    );
  }
}

export default withHome(Faq);

export const pageQuery = graphql`
  query ClientFaqPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      node_locale
      header {
        ...HeaderFragment
      }
      meta {
        ...MetaFragment
      }
    }
  }
`;
