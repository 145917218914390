import React from 'react';
import styled from 'styled-components';
import { palette, media } from '../utils/style';
import WhiteFrame from './whiteframe';
import Button from './Button';
import get from 'lodash/get';
import { navigate, graphql } from 'gatsby';
import { buildTo } from '../utils/i18n';

const Wrapper = styled.div`
  flex-basis: 50%;
  max-width: 50%;
  width: 50%;
  padding: 0;
  flex: 0 0 auto;
  display: flex;

  ${media.bigPhone`
   flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  `};
`;
const ContentCard = styled(WhiteFrame)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 2vw;
  padding: 10px;
`;
const Title = styled.h3`
  color: ${palette.text.tertiary};
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
`;
const Price = styled.div`
  font-size: 3.5em;
  font-weight: 600;
  margin: 0;
  position: relative;
  color: ${palette.text.primary};
`;
const PriceDetail = styled.p`
  font-size: 0.9em;
  font-weight: 600;
  opacity: 0.6;
  margin: 0;
  margin-top: -15px;
  color: ${palette.text.tertiary};
`;
const Dollar = styled.div`
  position: absolute;
  top: 18px;
  left: calc(50% - 50px);
  font-size: 20px;
`;
const CallToAction = styled(Button)`
  margin: 20px;
  padding: 15px;
  font-size: 0.8em;
  background-color: ${palette.primarySaturated};
`;
const CallToActionInverse = styled(CallToAction)`
  background-color: white;
  color: ${palette.primarySaturated};
  border: 2px solid ${palette.primarySaturated};
  padding: 13px;
  transition: background-color 0.15s, color 0.15s ease-in-out;
  &:hover {
    background-color: ${palette.primarySaturated};
    color: white;
  }
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  border-top: 1px solid ${palette.lightGray};
`;
const Feature = styled.div`
  font-size: 0.9em;
  color: ${palette.text.secondary};
  padding: 5px 0;
`;

const Name = styled.div`
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 400;
  color: ${palette.text.primary};
`;

// This component renders a pricing plan. It uses props bases
// on the "Plan" Content Model in Contentful
class Plan extends React.PureComponent {
  renderPrice = () => {
    const { price, priceDetail, name } = this.props;

    if (price) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Price>
            <Dollar>$</Dollar>
            {price}
          </Price>
          <PriceDetail>{priceDetail}</PriceDetail>
        </div>
      );
    }

    return (
      <Name>
        <div>{name}</div>
      </Name>
    );
  };

  _goToSignup = () => {
    navigate(buildTo('/signup', this.props.locale));
  };

  _openEmail = () => {
    const { locale } = this.props;
    const emailTo = 'info@keenoa.co';
    const emailSub =
      locale === 'en'
        ? "More info on Keenoa's enterprise plan"
        : "Plus d'information sur le plan Keenoa entreprise";
    location.href = 'mailto:' + emailTo + '?subject=' + emailSub;
  };

  render() {
    const { title, price, callToAction } = this.props;
    const features = get(this, 'props.features') || [];

    return (
      <Wrapper>
        <ContentCard>
          <Title>{title}</Title>
          {this.renderPrice()}
          <Features>
            {features.map((f, i) => (
              <Feature key={i}>{f}</Feature>
            ))}
          </Features>
          {!price ? (
            <CallToActionInverse onClick={this._openEmail}>
              {callToAction}
            </CallToActionInverse>
          ) : (
            <CallToAction onClick={this._goToSignup}>
              {callToAction}
            </CallToAction>
          )}
        </ContentCard>
      </Wrapper>
    );
  }
}
export default Plan;
