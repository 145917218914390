import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Meta from '../components/Meta';
import Header from '../components/Header';
import Benefit from '../components/Benefit';
import PageFooter from '../components/PageFooter';
import { withHome } from '../layouts/home';

class Home extends React.Component {
  render() {
    const data = get(this, 'props.data.contentfulClientHomePage');
    const meta = get(data, 'meta');
    const header = get(data, 'header');
    const benefits = get(data, 'benefits') || [];
    const footer = get(data, 'callToAction');

    return (
      <div>
        <Meta {...meta} />
        <Header {...header} />
        <div>
          {benefits.map((benefit, i) => (
            <Benefit
              key={i}
              {...benefit}
              image_side={i % 2 !== 0 ? 'left' : 'right'}
            />
          ))}
        </div>
        {footer ? <PageFooter {...footer} /> : null}
      </div>
    );
  }
}

export default withHome(Home);

export const pageQuery = graphql`
  query ClientPageQuery($id: String!) {
    contentfulClientHomePage(id: { eq: $id }) {
      header {
        ...HeaderFragment
      }
      meta {
        ...MetaFragment
      }
      benefits {
        ...BenefitFragment
      }
      callToAction {
        ...PageFooterFragment
      }
    }
  }
`;
