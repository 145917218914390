/*eslint-disable max-len,quotes*/
export default {
  'category.read': 'MIN LECTURE',
  'article.related': 'Article connexes',
  'subscribe.button': "S'abonner",
  'subscribe.placeholder': 'Inscrivez votre courriel pour notre infolettre.',
  'subscribe.thanks':
    'Merci ! Vous allez recevoir votre premier courriel sous peu.',

  'footer.terms': "Conditions d'utilisation",
  'footer.privacy': 'Polique relative à la vie privée',

  'features.step.assesment': 'Évaluation nutritionnelle',
  'features.step.teaching': 'Enseignement',
  'features.step.between': 'Entre les rencontres',
  'features.step.followUps': 'Suivi nutritionnel',
  'features.title': 'Fonctionnalités Keenoa utilisées',
};
