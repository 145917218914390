import React from 'react';
import styled from 'styled-components';
import { palette } from '../../utils/style';

const StyledInput = styled.input`
  display: inline-block;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 10px;
  position: relative;
  outline: none;
  appearance: none;
  background-color: transparent;
  border: 1.5px solid ${palette.midGray};
  border-radius: 3px;
  transition: border 0.25s ease-in-out;
  color: ${palette.text.primary};

  &:focus {
    border-color: ${palette.primary};
    outline: 0;
    box-shadow: none;
  }
`;

const TopLabel = styled.div`
  color: ${palette.primary};
  position: absolute;
  top: 2px;
  left: 12px;
  font-size: 10px !important;
  font-weight: 500 !important;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 18px 0;
`;

const Explanation = styled.div`
  color: ${palette.text.tertiary};
  font-size: 12px;
  font-weight: 600;
`;

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topLabelVisible: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && !this.state.topLabelVisible) {
      this.setState({
        topLabelVisible: true,
      });
    }
  }

  onChange = e => {
    const visible = e.target.value && e.target.value.length > 0;
    if (visible !== this.state.topLabelVisible) {
      this.setState({
        topLabelVisible: visible,
      });
    }
    this.props.onChange(e);
  };

  render() {
    return (
      <Wrapper>
        <StyledInput
          ref={x => (this.input = x)}
          {...this.props}
          onChange={this.onChange}
        />
        <TopLabel style={{ opacity: this.state.topLabelVisible ? 1 : 0 }}>
          {this.props.placeholder}
        </TopLabel>
        <Explanation> {this.props.explanation}</Explanation>
      </Wrapper>
    );
  }
}

export default Input;
