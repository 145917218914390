import React from 'react';
import get from 'lodash/get';
import Img from 'gatsby-image';

class Supporters extends React.PureComponent {
  renderSupporter = (supporter, i) => {
    const fixed = get(supporter, 'fixed');
    return <Img key={i} fixed={fixed} />;
  };

  render() {
    const supporters = get(this, 'props.supporters');
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {supporters.map(this.renderSupporter)}
      </div>
    );
  }
}
export default Supporters;
