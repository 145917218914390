import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { palette, media, useMedia } from '../utils/style';
import get from 'lodash/get';
import Testimonial from './Testimonial';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-directin: row;
`;

const ImgWrapper = styled.div`
  width: 175px;
  height: 175px;
  flex-shrink: 0;
  padding-top: 10px;
  border-radius: 8px 0 0;
  background-color: ${palette.accentBackground};

  > .gatsby-image-wrapper {
    height: 100%;
  }
`;

const ContentWrapper = styled.div`
  flex: 0;

  font-size: 26px;
  margin-right: -36px;

  ${media.giant`
   font-size: 24px;
  `};

  ${media.desktop`
    font-size: 22px;
  `};
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px;
  text-align: center;

  ${media.phone`
    margin-top: 24px;
    font-size: 14px;
  `};
`;

const QuoteBox = styled.div`
  margin-left: 18px;
  margin-right: -24px;
`;

function SideTestimonial(props) {
  const { name, content, photo } = props;
  const media = useMedia();

  if (media.tablet) return <Testimonial {...props} />;

  return (
    <Wrapper>
      <ImgWrapper>
        <Img
          fluid={photo.fluid}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'left bottom',
          }}
        />
        <Name>{name}</Name>
      </ImgWrapper>
      <QuoteBox>
        <ContentWrapper
          dangerouslySetInnerHTML={{
            __html: get(content, 'childMarkdownRemark.html'),
          }}
        />
      </QuoteBox>
    </Wrapper>
  );
}
export default SideTestimonial;
