import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { media, palette } from '../utils/style';
import Customer from './Customer';
import get from 'lodash/get';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  background-color: ${palette.accentBackground};

  ${media.tablet`
    padding: 12px;
    background-color: white;
  `};
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 500;
  color: ${palette.text.primary};
  max-width: 750px;
  margin-top: 0;
  text-align: center;

  ${media.bigPhone`
      text-align: left;
      margin-bottom: 5px;
  `};
`;

const CustomersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${media.tablet`
      opacity: 0.54;
      justify-content: center;
  `};
  ${media.bigPhone`
    margin: 0 -50px;
  `};
`;

function CustomersBanner({ title, customers }) {
  return (
    <Wrapper>
      <Title
        dangerouslySetInnerHTML={{
          __html: get(title, 'childMarkdownRemark.html'),
        }}
      />
      <CustomersWrapper>
        {customers.map(c => (
          <Customer colored {...c} />
        ))}
      </CustomersWrapper>
    </Wrapper>
  );
}
export default CustomersBanner;

export const CustomersBannerFragment = graphql`
  fragment CustomersBannerFragment on ContentfulCustomersBanner {
    title {
      childMarkdownRemark {
        html
      }
    }
    customers {
      ...CustomerFragment
    }
  }
`;
