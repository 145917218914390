import React from 'react';
import '../base.css';
import '../tabs.css';
import Container from '../../components/container';
import { IntlProvider } from 'react-intl';
import Footer from '../../components/Footer';
import TopBar from '../../components/TopBar';
import SiteMap from '../../components/Sitemap';
import { navigate, Link } from 'gatsby';
import { getUrlParameter } from '../../utils/misc';
import { setClientId } from '../../services/form';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { palette, media } from '../../utils/style';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;

const i18nMessages = defineMessages({
  covidBanner: { id: 'covid.banner' },
  covidButton: { id: 'covid.learnMore' },
});

const Banner = styled.div`
  height: 40px;
  background-color: ${palette.primary};
  position: fixed;
  left: 0%;
  top: 0px;
  right: 0px;
  bottom: auto;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  box-sizing: border-box;

  ${media.bigPhone`
    font-size: 14px;
    height: 60px;
  `};
`;

const BannerContent = styled.div`
  text-align: center;
  flex: 0 auto;
`;

const BannerLink = styled(Link)`
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
`;

class Template extends React.Component {
  componentDidMount() {
    const clientId = getUrlParameter('mobile_user');
    if (clientId) {
      setClientId(clientId);
      navigate('find-a-dietitian');
    }
  }
  renderBanner = () => {
    if (!FEATURE_FLAGS.BANNER_ENABLED) return null;

    return (
      <Banner>
        <BannerContent>
          <FormattedMessage {...i18nMessages.covidBanner} />{' '}
          <BannerLink
            to={
              this.props.locale === 'fr'
                ? 'fr/blog/covid-19-réponse'
                : 'blog/covid-19-response-extending-free-trial-removing-limits'
            }
          >
            <FormattedMessage {...i18nMessages.covidButton} />
          </BannerLink>
        </BannerContent>
      </Banner>
    );
  };

  render() {
    const { children, data, locale, messages } = this.props;
    return (
      <div style={{ position: 'relative', overflowX: 'hidden' }}>
        <IntlProvider locale={locale} messages={messages}>
          <Container>
            {this.renderBanner()}
            <TopBar locale={locale} data={data} />
            <div style={{ height: 40 }} />
            {children}
            <SiteMap locale={locale} data={data} />
            <Footer locale={locale} />
          </Container>
        </IntlProvider>
      </div>
    );
  }
}

export default Template;
