import React from 'react';
import jsonp from 'jsonp';
import { validate } from 'email-validator';
import styled from 'styled-components';
import { palette } from '../utils/style';
import { notify } from 'react-notify-toast';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

const messages = defineMessages({
  subscribe: { id: 'subscribe.button' },
  subscribePlaceholder: { id: 'subscribe.placeholder' },
  thanks: { id: 'subscribe.thanks' },
});

// Mailchimp endpoint
// From: https://us17.admin.mailchimp.com/lists/integration/embeddedcode?id=XXXXXX
// Where `XXXXXX` is the MC list ID
// Note: we change `/post` to `/post-json`
const MAILCHIMP_URL = `https://keenoa.us15.list-manage.com/subscribe/post-json?u=8e81dc4047871df60e2aa2c0c&amp;id=9c0a248e6a`;

const EmailInput = styled.input`
  display: inline-block;
  width: 250px;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  outline: none;
  appearance: none;
  background-color: transparent;
  border: 1.5px solid ${palette.lightGray};
  border-radius: 3px;
  transition: border 0.25s ease-in-out;
  color: ${palette.text.primary};
  font-size: 0.7em;

  &:focus {
    border-color: ${palette.primary};
    outline: 0;
    box-shadow: none;
  }
  ${props => (props.error ? `border-color: ${palette.error};` : '')};
`;

const CaptureForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ErrorMsg = styled.div`
  position: absolute;
  bottom: -1.5em;
  left: 2px;
  font-size: 0.6em;
  color: ${palette.error};
`;

const InputWrapper = styled.div`
  position: relative;
  ${props =>
    !props.large
      ? ''
      : `display: flex;
    flex-direction: column;
    width: 100%`};
`;

const SubscribeButton = styled.button`
  background-color: white;
  color: ${palette.gray};
  text-transform: uppercase;
  box-shadow: none;
  font-size: 0.7em;
  font-weight: 600;
  padding: 10px;
  margin-left: ${props => (props.large ? 0 : '5px')};
  margin-top: ${props => (props.large ? '5px' : 0)};
  border-radius: 3px;
  box-sizing: border-box;
  transition: box-shadow -webkit-text-stroke 0.15s ease-in-out;
  border: 1.5px solid ${palette.gray};

  &:focus {
    outline: none;
  }

  &:hover {
    filter: contrast(1.1);
    cursor: pointer;
    color: ${palette.primary};
    border-color: ${palette.primary};
  }

  &:disabled {
    filter: opacity(0.3);
  }
`;

class EmailCaptureForm extends React.Component {
  constructor() {
    super();
    this.state = {
      email: ``,
    };
  }

  // Update state each time user edits their email address
  _handleEmailChange = e => {
    this.setState({ email: e.target.value, status: undefined });
  };

  // Using jsonp, post to MC server & handle its response
  _postEmailToMailchimp = url => {
    // jsonp lib takes an `endpoint`, {options}, & callback
    jsonp(url, { param: `c` }, (err, data) => {
      // network failures, timeouts, etc
      if (err) {
        this.setState({
          status: `error`,
          msg: err,
        });
        notify.show(data.msg, err);

        // Mailchimp errors & failures
      } else if (data.result !== `success`) {
        this.setState({
          status: `error`,
        });
        notify.show(data.msg, 'error');

        // Posted email successfully to Mailchimp
      } else {
        this.setState({
          status: `success`,
        });
      }
    });
  };

  // On form submit, validate email
  // then jsonp to Mailchimp, and update state
  _handleFormSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    // If email is not valid, break early
    if (!validate(this.state.email)) {
      this.setState({
        status: `error`,
      });
      notify.show(
        `"${this.state.email}" is not a valid email address`,
        'error'
      );
      return;
    }

    // Construct the url for our jsonp request
    // Query params must be in CAPS
    // Capture pathname for better email targeting
    const url = `${MAILCHIMP_URL}
      &EMAIL=${encodeURIComponent(this.state.email)}
    `;

    this.setState(
      {
        msg: null,
        status: `sending`,
      },
      // jsonp request as setState callback
      this._postEmailToMailchimp(url)
    );
  };

  render() {
    const { large, intl } = this.props;
    return (
      <div>
        {this.state.status === `success` ? (
          <div>
            <FormattedMessage {...messages.thanks} />
          </div>
        ) : (
          <div>
            <CaptureForm id="email-capture" method="post" noValidate>
              <InputWrapper large={large}>
                <EmailInput
                  type="email"
                  name="email"
                  error={this.state.status === 'error'}
                  placeholder={intl.formatMessage(
                    messages.subscribePlaceholder
                  )}
                  onChange={this._handleEmailChange}
                  style={large ? { width: '100%' } : {}}
                />
                <SubscribeButton
                  type="submit"
                  onClick={this._handleFormSubmit}
                  large={large}
                >
                  <FormattedMessage {...messages.subscribe} />
                </SubscribeButton>
              </InputWrapper>
            </CaptureForm>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(EmailCaptureForm);
