import React from 'react';
import styled from 'styled-components';
import { shadow } from '../utils/style';
import PropTypes from 'prop-types';
import Color from 'color';
import { palette, media } from '../utils/style';

const gradientBackground = theme => (from, to) => {
  const actualFrom = from || palette.accent;
  const actualTo =
    to ||
    Color(actualFrom)
      .lighten(0.15)
      .hex();
  return {
    backgroundImage: `linear-gradient(90deg, ${actualFrom} 0%, ${actualTo} 100%)`,
  };
};

const StyledButton = styled.button`
  background-color: ${palette.primary};
  color: white;
  box-shadow: none;
  font-size: 16px;
  border: none;
  font-weight: 600;
  padding: 18px 30px;
  border-radius: 10px;
  box-sizing: border-box;
  transition: box-shadow -webkit-text-stroke 0.15s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    filter: contrast(1.1);
    cursor: pointer;
  }

  &:disabled {
    filter: opacity(0.3);
  }

  ${props => props.style};

  ${media.tablet`
    font-size: 0.9em;
  `};

  ${media.bigPhone`
    font-size: 0.9em;
  `};
`;

class Button extends React.PureComponent {
  render() {
    const {
      theme,
      elevated,
      elevation_level,
      gradient,
      gradient_from,
      gradient_to,
      style,
      className,
      disabled,
      onClick,
    } = this.props;
    const styleObject = {
      ...(elevated
        ? { boxShadow: shadow(elevation_level || 2, palette.accent) }
        : {}),
      ...(gradient
        ? gradientBackground(theme)(gradient_from, gradient_to)
        : {}),
      ...(style || {}),
    };

    return (
      <StyledButton
        className={className}
        style={styleObject}
        disabled={disabled}
        onClick={onClick}
      >
        {this.props.children}
      </StyledButton>
    );
  }
}

export default Button;

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,

  // see render methods for supported values
  style: PropTypes.any,
  className: PropTypes.string,

  elevated: PropTypes.bool, // Default False
  elevation_level: PropTypes.number, // Optional
  gradient: PropTypes.bool, // Default False
  gradient_from: PropTypes.string, // Default to theme accent
  gradient_to: PropTypes.string,
};
