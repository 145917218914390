import React from 'react';
import { addLocaleData } from 'react-intl';
import localeData from 'react-intl/locale-data/en';
import { graphql, StaticQuery } from 'gatsby';
import Layout from './home';
import messages from '../../i18n/home/en.js';

addLocaleData(localeData);

const query = graphql`
  query HomeQuery {
    contentfulAsset(
      contentful_id: { eq: "5upfvCFfeoiwkMkMSqgoEk" }
      node_locale: { eq: "en" }
    ) {
      fixed(width: 150) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    topBar: contentfulLinksBar(
      contentful_id: { eq: "2Jbra7uNJCscQuc4OeUYIq" }
      node_locale: { eq: "en" }
    ) {
      links {
        ...LinkFragment
        ...LinksGroupFragment
      }
    }
    siteMap: contentfulLinksBar(
      contentful_id: { eq: "5DFP8rJbZSqukYAgiu2geo" }
      node_locale: { eq: "en" }
    ) {
      links {
        ...LinkFragment
        ...LinksGroupFragment
      }
    }
  }
`;

export const HomeEn = props => (
  <StaticQuery
    query={query}
    render={data => {
      return <Layout data={data} {...props} locale="en" messages={messages} />;
    }}
  />
);
