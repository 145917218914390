import React from 'react';
import { buildTo } from '../utils/i18n';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const DEFAULT_HEIGHT = 62;

export default ({ fixed, locale, height = DEFAULT_HEIGHT }) => {
  return (
    <div
      style={{
        marginRight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: height,
        padding: '0 15px',
      }}
    >
      <Link to={buildTo('/', locale)}>
        <Img fixed={fixed} fadeIn={false} />
      </Link>
    </div>
  );
};
