const ENDPOINT_PREFIX = '/v1';

export const queryParams = params =>
  Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');

export const extendUrl = (url, params) =>
  params ? `${url}?${queryParams(params)}` : url;

const status = res => {
  if (res.status >= 200 && res.status < 400) {
    return Promise.resolve(res);
  } else {
    return res.json().then(res => Promise.reject(res));
  }
};

export function text(res) {
  return res.text();
}

export function json(res) {
  return res.json();
}

export const JSON_HEADER = { 'Content-type': 'application/json' };

export function insecureFetch(url, options = {}) {
  const extendedUrl = extendUrl(url, options.queryParams);
  const requestUrl = `${ENDPOINT_PREFIX}/${extendedUrl}`.replace(/\/\/+/g, '/');
  return fetch(requestUrl, options).then(status);
}
